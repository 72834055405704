import { ediSqtsEndpointMap } from '@core/nom/endpoints/ediSqtsEndpointMap';
import { schedulingEndpointMap } from '@core/nom/endpoints/schedulingEndpointMap';
import { addNotification } from '@core/notification';
import {
  Mutation,
  MutationCache,
  Query,
  QueryCache,
  QueryClient,
  QueryFunction,
  QueryKey
} from '@tanstack/react-query';
import { crossReferenceEndpointMap } from 'Sections/Nom/Pages/CrossReference/apis/crossReferenceEndpointMap';
import { handleRequest } from './lib';
import { DataMngrQueryFn, DataMngrQueryFnContext, DataMngrQueryMeta } from './typings';

const queryFn: DataMngrQueryFn = async ({ meta }: DataMngrQueryFnContext) => {
  return handleRequest(meta);
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: 'always', // Make requests even when offline to trigger error handling
      queryFn: queryFn as QueryFunction
    },
    mutations: {
      networkMode: 'always'
    }
  },
  queryCache: new QueryCache({
    onError: queryCacheOnError
  }),
  mutationCache: new MutationCache({
    onError: mutationCacheOnError
  })
});

const getErrorMessage = (error: any, meta: DataMngrQueryMeta): string | undefined => {
  const { name, key } = meta;

  if (error?.data?.Message) {
    return error.data.Message;
  }

  if (typeof name === 'string') {
    let endpointMap;
    if (name.startsWith('schedulingKeys.')) {
      endpointMap = schedulingEndpointMap;
    } else if (name.startsWith('crossReferenceKeys.')) {
      endpointMap = crossReferenceEndpointMap;
    } else if (name.startsWith('ediQueryKeys.')) {
      endpointMap = ediSqtsEndpointMap;
    }

    return endpointMap?.[key]?.queryError;
  }
};

function queryCacheOnError(error: any, query: Query<unknown, unknown, unknown, QueryKey>) {
  const errorMessage = getErrorMessage(error, query.meta as DataMngrQueryMeta);

  if (errorMessage) {
    return addNotification({
      id: `${query.meta?.name}Error`, // Use id to prevent duplicate notifications
      title: 'Error',
      message: errorMessage
    });
  }
}

function mutationCacheOnError(
  error: any,
  variables: unknown,
  context: unknown,
  mutation: Mutation<unknown, unknown, unknown>
) {
  const errorMessage = getErrorMessage(error, mutation.meta as DataMngrQueryMeta);

  if (errorMessage) {
    return addNotification({
      id: `${mutation.meta?.name}Error`,
      title: 'Error',
      message: errorMessage
    });
  }
}
