import { css } from './GridViewsToolPanel.style';

import { Btn, Text } from '@core/components';
import { useDidUpdate, useUpdate } from '@core/hooks';
import { DeleteOutlineSvg, SaveSvg } from '@core/images';
import { Select } from '@core/inputs';
import { addNotification } from '@core/notification';
import { useCss } from '@core/theme';
import { Nullable } from '@core/typings';
import { IToolPanelParams } from 'ag-grid-community';
import cn from 'classnames';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { components, OptionProps } from 'react-select';
import { Tooltip } from 'react-tooltip';
import {
  CustomGridView,
  GridViewsConfigTVal,
  GridViewsProviderParams
} from '../../GridStateConfig.types';
import { useGridViewsConfig } from '../../useGridViewsConfig';
import { formatCreateLabel } from './util';

/* TODO: this isn't technically accurate, but interface will change when upgrading to v31.2, so
 * I'm leaving it as-is for now. */
type GridViewsToolPanelProps = IToolPanelParams & GridViewsProviderParams;

export function GridViewsToolPanel(params: GridViewsToolPanelProps) {
  // const isOpen = params.api.getState()?.sideBar?.openToolPanel === gridViewsToolPanelId;
  const Config = useGridViewsConfig();
  const { availableViews: options } = Config;
  const update = useUpdate();
  const { activeGridView } = Config.getCurrent();

  /* tool panel component doesn't automatically re-render on updates, so make it do that */
  useEffect(() => {
    Config.on('change', update);
    Config.on('withGridViewsProviderStateChange', update);
    return () => {
      Config.off('change', update);
      Config.off('withGridViewsProviderStateChange', update);
    };
  }, []);

  useDidUpdate(() => {
    update();
  }, [options]);

  const selectComponents = useMemo(() => {
    function Option(props: OptionProps<CustomGridView>) {
      const { data = {} as any } = props;
      if (_.isEmpty(data) || !data) return <components.Option {...props} />;

      return (
        <components.Option {...props}>
          <div className={cn('option-wpr', { '--is-selected': props.isSelected })}>
            <Text text={data.label} id='title' />
            {data.isCustom && !_.isEmpty(params.presetMap) && (
              <div className='tag-wpr'>
                <Text text='Custom' id='extra' />
              </div>
            )}
          </div>
        </components.Option>
      );
    }
    return { Option };
  }, []);

  const [inputVal, setInputVal] = useState<string>('');
  const [saveType, setSaveType] = useState<'update' | 'create'>('update');

  const onCreateOption = useCallback(
    (v: string) => {
      const key = _.camelCase(v);

      Config.update(
        {
          activeGridView: key,
          customGridViews: [
            ...Config.getCurrent().customGridViews,
            {
              label: v,
              key,
              gridState: JSON.stringify(params.api.getState())
            }
          ]
        },
        { saveRemote: true }
      );
    },
    [inputVal]
  );

  const wpr = useCss(css);

  const handleRemoveCustomView = useCallback((key: string) => {
    const payload: Partial<GridViewsConfigTVal> = {
      activeGridView:
        Config.getCurrent().activeGridView === key ? null : Config.getCurrent().activeGridView,
      customGridViews: _.filter(Config.getCurrent().customGridViews, (v) => v.key !== key)
    };
    if (Config.getCurrent().defaultGridView === key) {
      payload.defaultGridView = payload.activeGridView;
    }
    Config.update(payload, { saveRemote: true });
  }, []);

  const renderCustomViewListItem = useCallback(
    (view: CustomGridView, index: number) => (
      <div key={index} className='custom-view-wpr'>
        <Text text={view.label} m className='custom-view-label' />
        <div className='custom-view-actions-wpr'>
          <Btn
            icon={DeleteOutlineSvg}
            color='danger'
            size='small'
            onClick={() => handleRemoveCustomView(view.key)}
          />
        </div>
      </div>
    ),
    []
  );
  const handleSave = useCallback(() => {
    if (saveType === 'create') {
      onCreateOption(inputVal);
    } else {
      Config.update(
        {
          customGridViews: _.map(Config.getCurrent().customGridViews, (v) => {
            if (v.key === Config.getCurrent().activeGridView) {
              return {
                ...v,
                gridState: JSON.stringify(params.api.getState())
              };
            }
            return v;
          })
        },
        { saveRemote: { silent: true } }
      );
      const activeViewConfig = _.find(Config.getCurrent().customGridViews, {
        key: Config.getCurrent().activeGridView
      }) as CustomGridView | undefined;
      if (activeViewConfig) {
        addNotification({
          title: 'Update Successful',
          message: `${activeViewConfig.label} has been updated.`,
          type: 'success'
        });
      }
    }
  }, [saveType, inputVal]);

  const onChange = useCallback((v: Nullable) => {
    Config.update({ activeGridView: v }, { saveRemote: { silent: true } });
  }, []);

  const onInputChange = useCallback((v: string) => {
    setInputVal((pVal) => {
      let ret = v;
      /* prevent blur from clearing input val */
      if (pVal?.length > 1 && !v?.length) ret = pVal;

      /* don't have to worry about resetting value; component unloads on value change */
      setSaveType(ret?.length > 2 ? 'create' : 'update');
      return ret;
    });
  }, []);

  const onDefaultViewChange = useCallback((v: Nullable) => {
    Config.update({ defaultGridView: v }, { saveRemote: true });
  }, []);

  return (
    <div className={wpr.wpr}>
      <div className='input-wpr'>
        <Text t='Active View' h6 className='label' />
        <div className='input-with-btn-ctr'>
          <Select<CustomGridView>
            formatCreateLabel={formatCreateLabel}
            onCreateOption={onCreateOption}
            options={options}
            onInputChange={onInputChange}
            onChange={onChange}
            components={selectComponents}
            inputValue={inputVal}
            labelKey='label'
            valueKey='key'
            value={activeGridView}
            minWidth={200}
            isCreatable
            isValidNewOption={(v) => v?.length > 2 && !_.find(options, { key: _.camelCase(v) })}
          />
          <Btn
            icon={SaveSvg}
            className='update-config-btn'
            onClick={handleSave}
            disabled={saveType === 'update' && !Config.hasActiveViewStateMeaningfullyChanged}
            bordered={saveType === 'update'}
          />
          {(Config.hasActiveViewStateMeaningfullyChanged || saveType === 'create') && (
            <Tooltip
              anchorSelect='.update-config-btn'
              content={
                saveType === 'create'
                  ? 'Create new View from current Table configuration'
                  : 'Update View with current Table configuration'
              }
              place='top-start'
            />
          )}
        </div>
        <Text t='Select a Custom View or start typing to create a new one.' className='hint' />
      </div>
      <div className='input-wpr'>
        <Text t='Default View' h6 className='label' />
        <Select<CustomGridView>
          {...{ options }}
          components={selectComponents}
          labelKey='label'
          valueKey='key'
          value={Config.getCurrent().defaultGridView}
          onChange={onDefaultViewChange}
          isClearable
        />
        <Text
          t='Set a default view to display when this page loads. If none is selected, the last active view will continue to display.'
          className='hint'
        />
      </div>
      {_.isEmpty(Config.current.customGridViews) ? null : (
        <div className='custom-views-list-wpr'>
          <Text t='Custom Views' h5 className='label custom-views-title' />
          <div className='custom-views-list-ctr'>
            {_.map(
              _.orderBy(Config.current.customGridViews, ['label'], ['asc']),
              renderCustomViewListItem
            )}
          </div>
        </div>
      )}
    </div>
  );
}
