import 'rc-switch/assets/index.css';
import { css } from './Switch.style';

import { Text } from '@core/components';
import { useCss } from '@core/theme';
import { cn } from '@core/util';
import _ from 'lodash';
import RcSwitch from 'rc-switch';
import { forwardRef, Ref } from 'react';
import { SwitchProps } from './Switch.types';

const checkedChildren = '\u2714';

export const Switch = forwardRef(function Switch(
  props: SwitchProps,
  ref: Ref<HTMLButtonElement>
): JSX.Element {
  const { color, className, gutter, inputClassName, label, labelProps, noTransition, ...rest } =
    props;
  const cls = useCss(css, { color, gutter, noTransition });

  return (
    <div className={cn('core-switch', cls.wpr, className)}>
      <RcSwitch
        checkedChildren={checkedChildren}
        {...rest}
        className={cn('switch-input', inputClassName)}
        ref={ref}
      />
      {(!!label || labelProps) && (
        <div className='switch-label'>
          {_.isString(label) || labelProps ? (
            <Text m {...labelProps} text={labelProps?.text || (label as string)} />
          ) : (
            label ?? null
          )}
        </div>
      )}
    </div>
  );
});
