import { AlertBar } from '@core/components';
import { useSetState, useValueMemo } from '@core/hooks';
import { useAppSelector } from '@core/redux';
import { useCss, useTheme } from '@core/theme';
import { cn } from '@core/util';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RootState } from 'redux/store';
import { ScreenWprContext } from './ScreenWpr.context';
import { css } from './ScreenWpr.style';
import { ScreenWprCV, ScreenWprLib, ScreenWprState } from './ScreenWpr.types';

export type ScreenWprProps = {
  children?: React.ReactNode;
};

export function ScreenWpr({ children }: ScreenWprProps) {
  const { isDark } = useTheme();
  const initialState = useMemo(() => ({ isDarkModePage: isDark }), []);
  const [state, setState] = useSetState<ScreenWprState>(initialState);
  const lib = useMemo<ScreenWprLib>(
    () => ({
      setIsDarkModePage: (isDarkModePage) => setState({ isDarkModePage }),
      resetIsDarkModePage: () => setState({ isDarkModePage: isDark })
    }),
    [isDark]
  );

  const value = useValueMemo<ScreenWprCV>(() => ({ ...state, ...lib }), [state, isDark]);
  const cls = useCss(css);
  const { t } = useTranslation();

  const featureFlags = useAppSelector((state: RootState) => state.featureFlags);
  const isAlertBarEnabled = featureFlags.alertBar;

  return (
    <ScreenWprContext.Provider value={value}>
      {isAlertBarEnabled && <AlertBar message={t('companySettings.uatEnvironment')} />}
      <div
        className={cn('screen-wpr', cls.screenWpr, {
          '--is-dark': isDark || state.isDarkModePage,
          '--is-alert-bar-enabled': isAlertBarEnabled
        })}
      >
        {children}
      </div>
    </ScreenWprContext.Provider>
  );
}
