import { DataMngrEndpointOptions } from '@core/data-manager';

export const schedulingEndpointMap: Record<string, DataMngrEndpointOptions> = {
  getSystemConfigs: {
    path: 'config/values/system/GSS',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: '8h',
    gcTime: '1d',
    queryError: 'Error getting system configs.'
  },
  updateSystemConfigs: {
    path: 'config/values/system/GSS',
    method: 'patch',
    queryError: 'Error updating system configs.'
  },
  getSingleCounterparty: {
    path: 'counterparties/:counterparty',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting counterparty.'
  },
  getUserConfigs: {
    path: 'config/values/user',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: 1,
    queryError: 'Error getting user configs.'
  },
  updateUserConfigs: {
    method: 'patch',
    path: 'config/values/user',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error updating user configs.'
  },
  getCrossRefereneContractMappings: {
    path: 'crossReferenceData/internalExternalMappings?externalAttribute=Contract&externalSystem=Pipeline&internalAttribute=ContractId',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: 0,
    queryError: 'Error getting cross reference contract mappings.'
  },
  getCrossReferenceMappingDefinition: {
    path: 'crossReferenceData/mappingDefinitions/:pipelineName',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting cross reference mapping definition.'
    // originalInbound: crossReferenceData/mappingDefinitions/KMP-DART Pipeline?externalXrefAttribute=Gid&internalXrefAttribute=Duns&direction=Inbound
    // originalOutbound: crossReferenceData/mappingDefinitions/KMP-DART Pipeline?externalXrefAttribute=Gid&internalXrefAttribute=CounterpartyId&direction=Outbound

    // these additional query params will be needed
    // Inbound --> ?externalXrefAttribute=Gid&internalXrefAttribute=Duns&direction=Inbound
    // Outbound --> ?externalXrefAttribute=Gid&internalXrefAttribute=CounterpartyId&direction=Outbound
  },
  getCrossReferenceAttributes: {
    path: 'crossReferenceData/attributes',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting cross reference attributes.'
  },
  getCrossReferenceExternalSystems: {
    path: 'crossReferenceData/externalSystems',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting cross reference external systems.'
  },
  getCrossReferenceDirections: {
    path: 'crossReferenceData/directions',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting cross reference directions.'
  },
  getNomsTypes: {
    path: 'logicalNom/nomTypes',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting nom types.'
  },
  getNomTypesByPipelineDuns: {
    path: 'logicalNom/nomTypesByPipelineDuns/:pipelineDuns',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting nom types by pipeline duns.'
  },
  getLocationsInterconnects: {
    path: 'locations/interconnects',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting location interconnects.'
  },
  getNomPadColumnContent: {
    path: 'nompadColumnContentTypes',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting NomPad column content.'
  },
  getUserPrivileges: {
    path: 'users/getuserprivileges',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting user privileges.'
  },
  getTrader: {
    path: 'users/findTrader',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting trader.'
  },
  getAllCounterparties: {
    path: 'counterparties',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting counterparties.'
  },
  updateCounterparties: {
    path: 'counterparties',
    method: 'patch',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error updating counterparties.'
  },
  getCounterpartiesUniqueIds: {
    path: 'counterparties/uniqueIds/:pipelineDuns',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting unique counterparty ids.'
  },

  updateContracts: {
    path: 'contracts',
    method: 'patch',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error updating contracts.'
  },
  getLocationTypes: {
    path: 'locations/locationtypes',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting location types.'
  },
  getAllPipelines: {
    path: 'pipelines',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting pipelines.'
  },
  updatePipeline: {
    path: 'pipelines',
    method: 'patch',
    queryError: 'Error updating pipeline.'
  },
  updateLocations: {
    path: 'locations/processLocations',
    method: 'patch',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error updating locations.'
  },
  getAllEbbExport: {
    path: 'ebbexport',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: 0,
    queryError: 'Error getting EBB export data.'
  },
  getEdiExportedNoms: {
    path: 'ebbexport/ediExportedNoms',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting EDI exported noms.'
  },
  getLogicalNomIds: {
    path: 'ebbexport/ediExportedNoms/nomStringId',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting logical nom IDs.'
  },
  getEbbExportedNomsCounts: {
    path: 'ebbexport/ebbExportedNomsCounts',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting EBB exported nom counts.'
  },
  getEbbExportedNoms: {
    path: 'ebbexport/ebbExportedNoms',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting EBB exported noms.'
  },
  getEbbSchema: {
    // TODO: Use useData filter instead of hardcoding query params here`
    path: 'ebbexport/schema?pipelineId=:pipelineId&businessUnitId=:businessUnitId',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting EBB schema.'
  },
  getAllLocations: {
    path: 'pipelines/alllocations',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting all locations.'
  },
  getSeasons: {
    path: 'pipelines/seasons',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting seasons.'
  },
  getPipelineSeasons: {
    path: 'pipelines/pipelineSeasons',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting pipeline seasons.'
  },
  getPipelineCycleIndicators: {
    path: 'pipelines/cycleIndicators/:pipelineDuns',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting pipeline cycle indicators.'
  },
  getSegments: {
    path: 'pipelines/segments',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting segments.'
  },
  getLocationsAtGroup: {
    path: 'locationGroups/locationAssociations',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting locations at group.'
  },
  getLocationsAtZone: {
    path: 'locations/locationsatzone/:zoneId',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting locations at zone.'
  },
  getAllZones: {
    path: 'pipelines/allzones',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting all zones.'
  },
  getAllContractsByPipeline: {
    path: 'contracts/:pipelineId',
    method: 'get',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting contracts by pipeline.'
  },
  getAllContracts: {
    path: 'contracts',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting all contracts.'
  },
  getCrossReferenceMappingValues: {
    path: 'crossReferenceData/mappingValues/:valueId',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting cross reference mapping values.'
  },
  getAllLocationGroups: {
    path: 'locationGroups',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting location groups.'
  },
  //GetTransactionTypeAliases
  getAllContractAliases: {
    path: 'contracts/aliases',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting contract aliases.'
  },
  getAllDealRanks: {
    path: 'deal/dealRanks',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting deal ranks.'
  },
  getDailyTradeVolume: {
    path: 'deal/dealDailyDetail/:flowDate/:endDate',
    method: 'post',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting daily trade volume.'
  },
  patchDealStagingDetails: {
    path: 'deal/dealDailyDetail',
    method: 'patch',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error saving deal staging details.'
  },
  getAllLogicalNomTags: {
    path: 'logicalNom/tags',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting logical nom tags.'
  },
  //------ HELP! -------- Not sure what to call this or what the variables are in this path
  getConfigValues: {
    path: 'config/values/plbu/:pipelineId/5005',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting config values.'
  },
  getConfigValuesPipeline: {
    path: 'config/values/pipeline/:pipelineId',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: 0,
    queryError: 'Error getting config values for pipeline.'
  },
  updateConfigValuesPipeline: {
    path: 'config/values/pipeline/:pipelineId',
    method: 'patch',
    queryError: 'Error updating config values for pipeline.'
  },
  getPipelineEmptyVolumeConfigs: {
    path: 'config/values/pipeline/name?name=pipeline.ebbExport.emptyVolumeDealLinking',
    method: 'get',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting pipeline deal linking configs.'
  },
  getAllContractTypes: {
    path: 'contracts/contractTypes',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting contract types.'
  },
  getContractRate: {
    path: 'rates/contract/:contractId',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting contract rate.'
  },
  getAllRateDefinitions: {
    path: 'rates/ratedefinitions',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting rate definitions.'
  },
  getAllRateSchedules: {
    path: 'rates/rateschedules',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting rate schedules.'
  },
  getPositionCalcTypes: {
    path: 'calcTypes/position',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting position calc types.'
  },
  getNompadColumns: {
    path: 'nompadColumns/pipeline/:pipelineId',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting NomPad columns.'
  },
  saveNompadConfigEntry: {
    path: 'nompadColumns/saveConfigEntry',
    method: 'post',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error saving column config.'
  },
  getLogicalNomPadEntries: {
    path: 'logicalNom/nompadEntries/:businessUnitId/:pipelineId/:flowDate/true',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: 0,
    queryError: 'Error getting logical NomPad entries.'
  },
  copyPlannedVolumePositions: {
    path: 'plannedVolumePosition/copyPlannedVolumePositionsSet',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    method: 'patch',
    queryError: 'Error copying Planned Volume Positions.'
  },
  getPlannedVolumePositions: {
    path: 'plannedVolumePosition/plannedVolumePositions/5005/:pipelineId/2023-06-27',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  },
  getPlannedVolumeCalcTypes: {
    path: 'calcTypes/plannedVolume',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting planned volume calc types.'
  },
  //------ HELP! -------- Not sure what the variables are in this path except the last one :)
  getSchedulingDay: {
    path: 'schedulingDay/5005/10187/2023-06-30/2023-06-30',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting scheduling day.'
  },
  getLogicalNomPipelineActivity: {
    path: 'logicalNom/pipelineActivityCodeElements/:pipelineId',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting logical nom pipeline activity.'
  },
  getDealsByFlowDate: {
    path: 'deal/deal/:businessUnitId/:pipelineId/:flowDate',
    queryError: 'Error getting deals by flow date.'
  },
  getDealTypes: {
    path: 'deal/dealTypes',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting deal types.'
  },
  getDealStagingEntries: {
    path: 'deal/dealStagingEntries',
    method: 'post',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting deals.'
  },
  saveDealStagingEntries: {
    path: 'deal/dealStaging',
    method: 'patch',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error saving deals.'
  },
  saveNomPadEntry: {
    path: 'logicalNom/nomPadEntry',
    method: 'patch',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error updating nomination details.'
  },
  saveNomPadEntries: {
    path: 'logicalNom/nomPadEntries/:startDate/:endDate',
    method: 'patch',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error saving nomination(s).'
  },
  getMergedNomPadEntryWithShapingDetails: {
    path: 'logicalNom/nomPadEntry/merge/:startDate/:endDate',
    method: 'post',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting merged NomPad entry with shaping details.'
  },
  getNomPadEntryByGuid: {
    path: 'logicalNom/nomPadEntry/guid/:logicalNomGuid/:flowDate/:computeShapedPropertySet',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting NomPad entry by GUID.'
  },
  copyLogicalNom: {
    path: 'logicalNom/copyLogicalNom',
    method: 'patch',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Nomination(s) could not be copied.'
  },
  getDuplicateCount: {
    path: 'logicalNom/duplicateNomCount',
    method: 'patch',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting duplicate nom count.'
  },
  sendIncludedNoms: {
    path: 'ebbexport',
    method: 'patch',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error sending included noms.'
  },
  submitEbbExport: {
    path: 'ebbexport',
    method: 'post',
    requestOptions: {
      responseType: 'blob'
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  },
  submitEdiExport: {
    path: 'ebbexport/ediSubmission',
    method: 'patch',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error submitting EDI export.'
  },
  getRateServiceTypes: {
    path: 'rates/servicetypes',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting rate service types.'
  },
  getRateLocationTypes: {
    path: 'rates/ratelocationtypes',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting rate location types.'
  },
  getProviderChargeTypes: {
    path: 'rates/providerchargetypes',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting provider charge types.'
  },
  getRateCategories: {
    path: 'rates/ratecategories',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting rate categories.'
  },
  getProcessingLog: {
    path: 'processingLog/GetAll',
    method: 'post',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting processing log.'
  },
  getMdqSummary: {
    path: 'mdqSummary/:businessUnitId/:pipelineId/:flowDate',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: 0,
    queryError: 'Error getting MDQ summary.'
  },
  getStorageReports: {
    path: 'contracts/storageReport/:businessUnitId/:pipelineId/:flowMonth',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting storage reports.'
  },
  exportNominations: {
    path: 'endurexport/exportnominations',
    method: 'post',
    queryError: 'Error exporting nominations.'
  },
  updateStorageLocations: {
    path: 'storageLocations',
    method: 'post',
    queryError: 'Error saving storage locations.'
  },
  getAgencyAssociations: {
    path: 'contracts/agencyAssociations',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting agency associations.'
  },
  updateAgencyAssociations: {
    path: 'contracts/agencyAssociations',
    method: 'patch',
    queryError: 'Error saving agency associations.'
  },
  getOperationStatus: {
    path: 'operationStatus/getStatus/string/:operationId/string/:processType',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting operation status.'
  },
  getDealTranStatuses: {
    path: 'static/dealTranStatuses',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting deal transaction statuses.'
  },
  updateDealTranStatuses: {
    path: 'static/dealTranStatuses',
    method: 'patch',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error updating deal transaction statuses.'
  },
  updatePlbuConfig: {
    path: 'config/values/plbu/:pipelineId/:businessUnitId',
    method: 'patch',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error saving internal values.'
  }
};
