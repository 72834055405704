import { DropdownMenu, IMenuEntry } from '@core/components';
import { useGetSetState, useValueMemo } from '@core/hooks';
import { ArrowBarLeftSvg, CloseOutlineSvg, SaveOutlineSvg } from '@core/images';
import { JsonMap } from '@core/typings';
import { useConfig } from '@core/user-configs';
import cn from 'classnames';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useMemo } from 'react';
import { FilterSetContext } from './FilterSet.context';
import {
  FieldPropsMap,
  FilterSetCV,
  FilterSetLib,
  FilterSetProps,
  FilterSetState
} from './FilterSet.types';

export function FilterSet<TFilters extends JsonMap>(props: FilterSetProps<TFilters>) {
  type TKey = keyof TFilters;
  const {
    className,
    configId,
    filters,
    isClearable = true,
    isSaveable = true,
    hideMenu = false,
    title
  } = props;

  const initialState = useMemo<FilterSetState<TFilters>>(
    () => ({
      isOpen: true,
      fieldProps: {}
    }),
    []
  );

  const [getState, setState] = useGetSetState<FilterSetState<TFilters>>(initialState);
  const Config = useConfig<TFilters>(configId);

  const lib = useMemo<FilterSetLib<TFilters>>(
    () => ({
      setFieldValue: (key: TKey, value: TFilters[typeof key]) => {
        Config.update({ [key]: value } as Partial<TFilters>);
      },
      setFieldProps: (
        keyOrPayload: TKey | Partial<FieldPropsMap<TFilters>>,
        props?: typeof keyOrPayload extends string ? unknown : undefined
      ) => {
        const payload = _.isString(keyOrPayload)
          ? { [keyOrPayload]: props }
          : (keyOrPayload as Partial<FieldPropsMap<TFilters>>);
        setState((pState) => ({ fieldProps: { ...pState.fieldProps, ...payload } }));
      },
      setFieldValues: (payload: Partial<TFilters>) => Config.update(payload),

      getFieldValue: (key: TKey) => _.get(Config.getCurrent(), key),
      setIsOpen: (isOpen) => {
        setState({ isOpen });
      },
      get Config() {
        return Config;
      }
    }),
    []
  );

  const value = useValueMemo<FilterSetCV<TFilters>>(
    () => ({
      ...lib,
      ...getState()
    }),
    [getState()]
  );

  if (!Config.isHydrated) return null;

  return (
    <FilterSetContext.Provider value={value as unknown as FilterSetCV<JsonMap>}>
      <div className={cn('filter-set', className)}>
        <div className='ctr'>
          <div className='title-wpr'>{title}</div>
          {_.map(filters, (filter, key) => {
            const { input: El, render, children } = filter;
            return (
              <div {...{ key }} className='filter-wpr'>
                {El ? <El /> : render ? render() : children}
              </div>
            );
          })}
        </div>
        {!hideMenu && (isClearable || isSaveable) && (
          <DropdownMenu
            className='filter-set-menu'
            items={_.compact<IMenuEntry>([
              isClearable
                ? {
                    icon: CloseOutlineSvg,
                    title: 'Clear All Filters',
                    onClick: () => Config.reset(),
                    disabled: Config.isInitial
                  }
                : null,
              ...(isSaveable
                ? [
                    {
                      icon: SaveOutlineSvg,
                      title: 'Save Filters',
                      onClick: () => Config.save(),
                      description: `Last Saved ${dayjs(Config.savedAt).fromNow()}.`,
                      disabled: !Config.isDirty
                    },
                    {
                      title: 'Revert to Saved',
                      onClick: () => Config.revertToSaved(),
                      icon: ArrowBarLeftSvg,
                      description: 'Reset Filters to most recently saved values.',
                      disabled: !Config.isDirty
                    }
                  ]
                : [])
            ])}
          />
        )}
      </div>
    </FilterSetContext.Provider>
  );
}
