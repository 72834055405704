export default {
  nomDetailsPage: {
    nomDetails: {
      supply: 'Supply',
      demand: 'Demand',
      header: { id: 'Nomination ID', flowDate: 'Flow Date' },
      nomHeader: {
        contract: {
          columns: {
            startDate: 'Start Date',
            endDate: 'End Date',
            ta: 'TA',
            tt: 'TT',
            packageId: 'Pkg ID',
            accountCode: 'Acct Code',
            rank: 'Rank',
            fuelLoss: 'Fuel Loss'
          },
          rows: { contract: 'Contract' }
        },
        nom1: {
          columns: {
            rank: 'Rank',
            packageId: 'Pkg ID',
            counterparty: 'CPTY',
            duns: 'DUNS',
            svcReqK: 'Svc Req K',
            tt: 'TT',
            meter: 'MTR',
            zone: 'ZN'
          },
          rows: { supply: 'Receipt', demand: 'Delivery' }
        },
        nom2: {
          columns: {
            location: 'LOC',
            nominatedVolume: 'Nom Vol',
            remVol: 'Rem Vol',
            scheduledVolume: 'Sch Vol'
          }
        }
      },
      nomVolume: {
        showWholeVolumeMonths: 'Show Whole Month Volumes',
        colGroupHeaders: {
          fixedColumns: 'Fixed Columns',
          ebbInfo: 'EBB Info',
          dailyValues: 'Daily Values',
          endurInfo: 'ETRM Info',
          housekeeping: 'Housekeeping'
        },
        associatedDeals: {
          demandDeals: 'Demand Deals',
          supplyDeals: 'Supply Deals',
          table: {
            dealTrackingNumDisplay: {
              header: 'ETRM Deal Number'
            },
            rankNumber: { header: 'Rank Number' },
            nomDayTradeVolume: { header: 'Trading' },
            nominatedVolume: { header: 'Nominated' },
            id: { header: 'Id' },
            logicalNomId: { header: 'LogicalNom Id' },
            logicalNomGuid: { header: 'LogicalNom Guid' },
            modelState: { header: 'Model State' },
            modifiedBy: { header: 'Modified By' },
            modifiedDate: { header: 'Modified Date' },
            createdBy: { header: 'Created By' },
            createdDate: { header: 'Created Date' },
            rowVersion: { header: 'Row Version' }
          },
          footer: { allocationImbalance: 'Allocation Imbalance', totalNominated: 'Total Nominated' }
        },
        footer: {
          totalReceiptQty: 'Rec Qty Total',
          selectedReceiptQty: 'Rec Qty Selected',
          totalFuelQty: 'Fuel Qty Total',
          selectedFuelQty: 'Fuel Qty Selected',
          totalDeliveryQty: 'Del Qty Total',
          selectedDeliveryQty: 'Del Qty Selected'
        }
      }
    }
  }
} as const;
