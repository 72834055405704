const notifications = {
  notifications: {
    error: 'Error',
    warning: 'Warning',
    success: 'Success',
    companySettingsUpdated: 'Company Settings have been successfully updated.',
    userCreation:
      'User successfully created. In a few moments they will receive an email invitation and password reset instructions.',
    userCreationError: 'Error creating user.',
    userDeletion: 'User was successfully deleted.',
    userUpdated: 'User was successfully updated.',
    userUpdateError: 'Error updating user.',
    userSuspended: 'User was successfully suspended.',
    userActivated: 'User was successfully activated.',
    specRequestCreation: 'Spec request was submitted successfully.',
    specRequestError: 'Unable to create spec request.',
    referenceDataDeletedSuccess: 'Reference data was successfully deleted.',
    referenceDataDeletedError: 'Error deleting reference data.',
    referenceDataDeletedFailure:
      'Cannot delete records that are being referenced. ETRM IDs: {{ids}}',
    roleCreated: 'Role was successfully created.',
    roleCreatedError: '{{error}}',
    roleUpdated: 'Role was successfully updated.',
    roleUpdatedError: '{{error}}',
    roleDeleted: 'Role was successfully deleted.',
    roleDeletedError: '{{error}}',
    resourceCreation: 'Resource was successfully created.',
    workflowStatusSuccess: 'Workflow status successfully updated.',
    workflowStatusError: 'Error updating workflow status.',
    insufficientPermissions: 'Insufficient Permissions: Unable to perform the requested action.',
    routeBlocked: {
      title: 'Insufficient Permissions',
      /* NOTE: the '-' in {{- route}} escapes slash interpolation. Don't remove it. */
      message:
        'You have been redirected to the previous page because you do not have permission to view {{- route}}.'
    },
    rejectedInvoiceDeletedSuccess: 'Rejected invoice was successfully deleted.',
    rejectedInvoiceResubmitSuccess: 'Rejected invoice was successfully resubmitted.',
    chargeTypeUpdateSuccess: 'Charge type updated successfully across invoices.',
    chargeTypeUpdateError: 'Failed to update charge type across invoices.',
    transAgreementUpdateSuccess: 'Transportation agreement updated successfully across invoices.',
    transAgreementUpdateError: 'Failed to update transportation agreement across invoices.',
    dealReplicaDeleted: 'Deal replica table was successfully deleted from selected date.',
    dealReplicaDeleteError: 'Error deleting records from the deal replica table.',
    deleteNomError:
      'One or more nominations cannot not be deleted because they have already been exported to the EBB; volumes should be set to 0 instead of deleting.',
    fileUploadSuccess: 'File uploaded successfully.',
    fileUploadError: 'Error uploading file.',
    missingFields: 'Please fill in all required fields.',
    unsavedChanges: 'There are unsaved changes. Do you want to save them before proceeding?'
  }
} as const;

export default notifications;
