import { DataMngrEndpointOptions } from '@core/data-manager';

export const crossReferenceEndpointMap: Record<string, DataMngrEndpointOptions> = {
  getXRefMappingDefinitions: {
    path: 'crossReferenceData/mappingDefinitions',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting cross reference mapping definitions.'
  },
  getXRefMappingValues: {
    path: 'crossReferenceData/mappingValues/:mappingDefinitionId',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error getting cross reference mapping values.'
  },
  updateXRefMappingValues: {
    path: 'crossReferenceData/mappingValues/:mappingDefinitionId',
    method: 'patch',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryError: 'Error saving mapping values.'
  }
};
