const settle = {
  settle: {
    title: 'Settle',
    uploadInvoice: {
      uploadTitle: 'Ox Settle',
      uploadBody:
        'The Eleox platform is driven by proprietary data mapping tables. Eleox members gain access to full system integration. Non-members can still use automated settlement features by downloading the Eleox Invoicing Data Template, and then upload their invoices here.',
      bannerTitle: 'Stay in the know of your invoicing and track actionable items',
      bannerBody:
        'Get started by clicking on Upload to add a file or drag and drop existing files into Eleox',
      uploadPipeline: 'Upload for Pipeline',
      dataSet: 'Download Templates'
    },
    rejectedEtrmInvoices: {
      title: 'Rejected ETRM Invoices',
      table: {
        checkbox: '',
        rejectedReason: 'Rejected Reason',
        rejectedDate: 'Rejected Date',
        pipelines: 'Pipelines',
        externalCounterparties: 'External Counterparties',
        internalCounterparties: 'Internal Counterparties',
        paymentDates: 'Payment Dates',
        accountingMonths: 'Accounting Months',
        invoiceNumbers: 'Invoice Numbers',
        invoiceStatuses: 'Invoice Statuses'
      },
      resubmitModal: {
        header: 'Are you sure you want to resubmit?',
        body: 'The selected rejected invoices will be resubmitted.',
        confirm: 'Yes',
        cancel: 'Cancel'
      }
    },
    table: {
      pipelineInvoice: {
        amountDue: 'Amount Due',
        counterpartyDuns: 'Counterparty DUNS',
        invoiceNumber: 'Invoice Number',
        pipelineDuns: 'Pipeline DUNS',
        pipelineName: 'Pipeline Name',
        pipelineNomSystem: 'Pipeline Nom System',
        quantity: 'Qty',
        rateCode: 'Rate Code',
        rateComp: 'Rate Comp',
        serviceCode: 'Svc Code',
        serviceRequestContract: 'Svc Request Contract',
        serviceType: 'Svc Type',
        unitPrice: 'Unit Price',

        /* optional */
        calcFactor: 'Calc Factor',
        chargeCode: 'Charge Code',
        chargeType: 'Charge Type',
        counterpartyName: 'Counterparty Name',
        deliveryLocationName: 'Delivery Loc Name',
        deliveryLocationPublicMeterId: 'Delivery Loc Public Meter ID',
        endDate: 'End Date',
        flowMonth: 'Flow Month',
        priorPeriodAdjustment: 'Prior Period Adj',
        receiptLocationName: 'Receipt Loc Name',
        receiptLocationPublicMeterId: 'Receipt Loc Public Meter ID',
        startDate: 'Start Date',
        transactionType: 'Transaction Type'
      }
    },
    invoiceReconciliation: {
      title: 'Invoice Reconciliation',
      description: '',
      deleteCommentModal: {
        header: 'Are you sure you want to delete?',
        body: 'The comment will be permanently deleted.',
        confirm: 'Yes',
        cancel: 'Cancel'
      }
    }
  }
} as const;

export default settle;
