import { useCss } from '@core/theme';
import { css, TransferStyleProps } from './Transfer.style';

import { Btn } from '@core/components';
import { useDidUpdate, useGetSetState, useValueMemo } from '@core/hooks';
import { ChevronLeftSvg, ChevronRightSvg } from '@core/images';
import { ClassName, Nullable } from '@core/typings';
import { cn } from '@core/util';
import { GridReadyEvent, IRowNode, RowNode } from 'ag-grid-community';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import _ from 'lodash';
import { forwardRef, MutableRefObject, useCallback, useImperativeHandle, useRef } from 'react';

type GridProps<TData extends object = any> = Omit<
  Partial<AgGridReactProps<TData>>,
  'rowData' | 'rowSelection'
>;

export type TransferProps<
  TValue extends object = any,
  TOption extends object = TValue,
  IsMultiSelect extends boolean = false | true
> = {
  className?: ClassName;
  defaultGridProps?: GridProps;
  /** whether multiple rows can be added/removed */
  isMultiSelect?: IsMultiSelect extends true ? true : false;
  onAdd?: (options: IsMultiSelect extends true ? TOption[] : Nullable<TOption>) => void;
  onRemove?: (value: IsMultiSelect extends true ? TValue[] : Nullable<TValue>) => void;
  options: TOption[];
  optionsGridProps: GridProps<TOption>;
  value: TValue[];
  valueGridProps: GridProps<TValue>;
  isDisabled?: boolean;
} & TransferStyleProps;

type TransferState = {
  canAdd: boolean;
  canRemove: boolean;
  isDisabled: boolean;
};

const initialState: TransferState = {
  canAdd: false,
  canRemove: false,
  isDisabled: false
};

/* NOTE: `any` is used here to conform with ag-grid's default type for row data */
export const Transfer = forwardRef<any, TransferProps>(function Transfer<
  TValue extends object = any,
  TOption extends object = TValue,
  IsMultiSelect extends boolean = false | true
>(props: TransferProps<TValue, TOption, IsMultiSelect>, ref: any) {
  const {
    className,
    defaultGridProps,
    isDisabled: _isDisabled = false,
    isMultiSelect = true,
    onAdd,
    onRemove,
    options,
    optionsGridProps,
    value = [],
    valueGridProps,

    /* style props */
    color = 'primary',
    rtl
  } = props;
  const [getState, setState] = useGetSetState<TransferState>({
    ...initialState,
    isDisabled: _isDisabled
  });

  const state = getState();

  const getButtonState = useCallback((nValue?: boolean): Partial<TransferState> => {
    const { isDisabled: pValue } = getState();
    const isDisabled = _.isBoolean(nValue) ? nValue : pValue;
    return {
      canAdd: !isDisabled && !!getOptionsGrid()?.api.getSelectedRows()?.length,
      canRemove: !isDisabled && !!getValueGrid()?.api.getSelectedRows()?.length
    };
  }, []);
  const setButtonState = useCallback((nVal?: boolean) => setState(getButtonState(nVal)), []);

  const isOptionRowSelectable = useCallback(
    (params: IRowNode<TOption>) =>
      !getState().isDisabled &&
      ((optionsGridProps.isRowSelectable || crossGridDefaults.isRowSelectable)?.(params) ?? true),
    []
  );
  const isValueRowSelectable = useCallback(
    (rowNode: IRowNode<TValue>) =>
      !getState().isDisabled &&
      ((valueGridProps.isRowSelectable || crossGridDefaults.isRowSelectable)?.(rowNode) ?? true),
    []
  );

  /* left-side (value) grid */
  const valueGridRef = useRef<AgGridReact<TValue>>(null);
  const getValueGrid = useCallback(() => valueGridRef.current, []);

  useDidUpdate(() => {
    valueGridRef?.current?.api.setGridOption('rowData', value);
    setButtonState();
  }, [value]);

  /* right-side (options) grid */
  const optionsGridRef = useRef<AgGridReact<TOption>>(null);
  const getOptionsGrid = useCallback(() => optionsGridRef.current, []);

  /* track value of user-provided disabled flag */
  useDidUpdate(() => {
    setState({ isDisabled: _isDisabled, ...getButtonState(_isDisabled) });
    /* update selectability of each row in the grid */
    getOptionsGrid()?.api.forEachNode((node) => {
      (node as unknown as RowNode).setRowSelectable(isOptionRowSelectable(node));
    });
    getValueGrid()?.api.forEachNode((node) => {
      (node as unknown as RowNode).setRowSelectable(isValueRowSelectable(node));
    });
  }, [_isDisabled]);

  useDidUpdate(() => {
    optionsGridRef?.current?.api.setGridOption('rowData', options);
    setButtonState();
  }, [options]);

  useImperativeHandle(
    ref,
    () => ({
      get optionsGrid() {
        return getOptionsGrid();
      },
      get valueGrid() {
        return getValueGrid();
      },
      get state() {
        return getState();
      },
      getState
    }),
    []
  );

  const cls = useCss(css, { color, rtl });
  const crossGridDefaults = useValueMemo<Partial<AgGridReactProps>>(
    () => ({
      stopEditingWhenCellsLoseFocus: true,
      ...defaultGridProps,
      rowSelection: isMultiSelect ? 'multiple' : 'single'
    }),
    []
  );
  /* universal onGridReady */
  const onGridReady = useCallback((event: GridReadyEvent, cb?: AgGridReactProps['onGridReady']) => {
    event.api.hideOverlay();
    cb?.(event);
  }, []);

  return (
    <div className={cn('transfer-wpr', cls.wpr, className, { '--is-disabled': state.isDisabled })}>
      <div className='grid-wpr value'>
        {/* Value grid */}
        <AgGridReact<TValue>
          {...{ ...crossGridDefaults, ...valueGridProps }}
          className={cn(
            'transfer-grid value',
            crossGridDefaults.className,
            valueGridProps.className
          )}
          /* explicit props (CANNOT override) */
          ref={valueGridRef}
          isRowSelectable={isValueRowSelectable}
          onSelectionChanged={(params) => {
            setButtonState();
            (valueGridProps.onSelectionChanged || crossGridDefaults.onSelectionChanged)?.(params);
          }}
          onGridReady={(event) => {
            event.api.setGridOption('rowData', value);
            onGridReady(event, valueGridProps.onGridReady || crossGridDefaults.onGridReady);
          }}
        />
      </div>
      <div className='transfer-btns-wpr'>
        <Btn
          color={color}
          bordered={false}
          dense
          data-testid='transfer-add-btn'
          icon={ChevronLeftSvg}
          onClick={() => {
            const rows = optionsGridRef?.current?.api.getSelectedRows();
            onAdd?.(isMultiSelect ? rows ?? [] : ((rows?.[0] ?? null) as any));
          }}
          disabled={state.isDisabled || !state.canAdd}
        />
        <Btn
          color={color}
          bordered={false}
          dense
          data-testid='transfer-remove-btn'
          icon={ChevronRightSvg}
          onClick={() => {
            const rows = valueGridRef?.current?.api.getSelectedRows();
            onRemove?.(isMultiSelect ? rows ?? [] : ((rows?.[0] ?? null) as any));
          }}
          disabled={state.isDisabled || !state.canRemove}
        />
      </div>
      {/* Options Grid */}
      <div className='grid-wpr options'>
        <AgGridReact<TOption>
          {...{ ...crossGridDefaults, ...optionsGridProps }}
          /* explicit props (CANNOT override) */
          ref={optionsGridRef}
          isRowSelectable={isOptionRowSelectable}
          onGridReady={(grid) => {
            grid.api.setGridOption('rowData', options);
            onGridReady(grid, optionsGridProps.onGridReady || crossGridDefaults.onGridReady);
          }}
          className={cn(
            'transfer-grid options',
            crossGridDefaults.className,
            optionsGridProps.className
          )}
          onSelectionChanged={(params) => {
            setButtonState();
            (optionsGridProps.onSelectionChanged || crossGridDefaults.onSelectionChanged)?.(params);
          }}
        />
      </div>
    </div>
  );
}) as <
  TValue extends object = any,
  TOption extends object = TValue,
  IsMultiSelect extends boolean = false | true
>(
  props: TransferProps<TValue, TOption, IsMultiSelect> & { ref?: MutableRefObject<any> }
) => JSX.Element;
