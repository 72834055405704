import { StyleGetter } from '@core/theme/typings';
import _ from 'lodash';
import { Colors as SelectColors, ThemeSpacing } from 'react-select';
import { SelectStyleProps, StyleOverrides } from './Select.types';

export const getStyles: StyleGetter<SelectStyleProps> = (
  _theme,
  { color = 'primary', minWidth }
) => {
  const {
    colors: { primary, canvas, danger, text },
    darken,
    fade,
    getColor,
    gutter,
    getTextColor
  } = _theme;
  const main = getColor(color);

  const styles: StyleOverrides = {
    control: (base, state) => ({
      ...base,
      boxShadow: state.isFocused ? 'none' : base.boxShadow,
      ...(_.isUndefined(minWidth) ? {} : { minWidth }),
      '&:hover': state.isDisabled
        ? {}
        : {
            borderColor: state.theme.colors.primary75
          },
      alignItems: 'center',
      backgroundColor: state.isDisabled ? canvas.disabled : canvas.primary,
      '.select-inline-label-wpr': {
        display: 'flex',
        alignSelf: 'center',
        padding: `1.5px 0 1.5px 6px`,
        '.select-inline-label': {
          color: text.hint,
          lineHeight: 1,
          textTransform: 'none' /* DO NOT CHANGE THIS. OVERRIDE LOCALLY! */,
          '&.--is-placeholder': {
            paddingTop: 2
          }
        }
      }
    }),
    clearIndicator: (base, state) => {
      return {
        ...base,
        '&:hover': {
          ...(base?.['&:hover'] as unknown as object),
          color:
            state.isDisabled || state.data?.isDisabled
              ? text.disabled
              : (fade(state.theme.colors.danger, 25) as string)
        }
      };
    },
    dropdownIndicator: (base, state) => ({
      ...base,
      color: state.isDisabled ? text.disabled : base.color,
      transition: 'transform 150ms ease-in-out',
      transform: `rotateX(${state.selectProps.menuIsOpen ? '-0.5' : '0'}turn)`,
      '&:hover': state.isDisabled
        ? {}
        : {
            ...(base?.['&:hover'] as unknown as object),
            color: state.theme.colors.primary
          }
    }),
    indicatorSeparator: (base, state) => ({
      ...base,
      backgroundColor: state.isDisabled
        ? (darken(canvas.disabled, 25) as string)
        : base.backgroundColor
    }),
    menu: (base) => ({ ...base, marginTop: 2, zIndex: 9999 }),
    multiValue: (base, state) => {
      const isDisabled = state.isDisabled || state.data?.isDisabled;
      const bg = isDisabled ? darken(canvas.disabled, 17) : state.theme.colors.primary;
      return {
        ...base,
        backgroundColor: bg,
        color: isDisabled ? text.disabled : (getTextColor(bg) as string)
      };
    },
    multiValueLabel: (base, state) => {
      const isDisabled = state.isDisabled || state.data?.isDisabled;
      const bg = isDisabled ? canvas.disabled : state.theme.colors.primary;
      return {
        ...base,
        color: isDisabled ? text.disabled : (getTextColor(bg) as string)
      };
    },
    option: (base, state) => {
      return {
        ...base,
        ...(state.isDisabled
          ? {
              color: text.disabled,
              backgroundColor: canvas.disabled,
              cursor: 'not-allowed'
            }
          : {})
      };
    },
    singleValue: (base, state) => {
      return {
        ...base,
        color: state.isDisabled ? text.disabled : primary.primary,
        fontSize: 14
      };
    }
  };

  return {
    styles,
    defaultTheme: {
      borderRadius: gutter / 2,
      colors: {
        primary: main,
        primary75: fade(main, 25),
        primary50: fade(main),
        primary25: fade(main, 75),
        danger: danger.primary,
        dangerLight: danger.veryLight
      } as SelectColors,
      spacing: {
        baseUnit: 3,
        controlHeight: 34
      } as ThemeSpacing
    }
  };
};
