import { OxRoute, ProductKey } from '@core/typings';
import { RootState } from 'redux/store';

/*
  TODO: this should be converted to a hook, and useAppSelector shouldn't be passed as a param.
  This also needs to be refactored to use DOA permissions instead of using product permissions
*/
export function getVerifiedRoutes(
  routes: OxRoute[],
  writeRequiredPaths: string[],
  product: ProductKey,
  selector: any
) {
  const { currentUser } = selector((state: RootState) => state.access);

  return routes.filter((route) => {
    const isWriteRequired = writeRequiredPaths.findIndex((path) => path === route.path) > -1;
    const roleValue = currentUser.role?.value;
    const isAdminOrSuperUser = roleValue === 'super-user' || roleValue === 'admin';
    const isOxAdminOrSettings = product === 'oxAdmin' || product === 'settings';
    const isWritable = (isAdminOrSuperUser && isOxAdminOrSettings) || !isOxAdminOrSettings;
    return !isWriteRequired || isWritable;
  });
}
