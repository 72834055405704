import { useValueMemo } from '@core/hooks';
import { ClassName, PanOptional } from '@core/typings';
import { cn } from '@core/util';
import _ from 'lodash';
import { ForwardedRef, forwardRef, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { TextDiv } from './TextDiv.style';
type TTuple = [string, Record<string, any>];

export interface TextProps extends Omit<HTMLAttributes<HTMLDivElement>, 'className' | 'translate'> {
  active?: boolean;
  b?: boolean;
  bold?: boolean;
  code?: boolean;
  color?: string;
  debug?: boolean;
  disabled?: boolean;
  em?: boolean;
  fontSize?: string | number;
  h1?: boolean;
  h2?: boolean;
  h3?: boolean;
  h4?: boolean;
  h5?: boolean;
  h6?: boolean;
  i?: boolean;
  m?: boolean;
  medium?: boolean;
  mono?: boolean;
  size?: string | number;
  strikethrough?: boolean;
  t?: PanOptional<string | TTuple>;
  text?: PanOptional<string>;
  tPath?: PanOptional<string>;
  tParams?: Record<string, unknown>;
  translate?: boolean;
  u?: boolean;
  underline?: boolean;
  className?: ClassName;
  dataTestId?: string;
}

export const Text = forwardRef(function Text(
  props: TextProps,
  ref: ForwardedRef<any>
): JSX.Element {
  /* prettier-ignore */
  const {
    active,
    b, bold = b,
    children = null,
    className,
    code,
    color,
    disabled,
    i, em = i,
    translate = true,
    m, medium = m,
    size, fontSize = size,
    h1, h2, h3, h4, h5, h6,
    strikethrough,
    text,
    tPath = false, t = tPath,
    tParams = {},
    u, underline = u,
    dataTestId = '',
    ...rest
  } = props;

  const { t: _t } = useTranslation();
  const tValue = useValueMemo<string | boolean>(
    () =>
      _.isString(t) ? (translate ? _t(t as string, tParams) : t) : _.isArray(t) ? _t(...t) : false,
    [t, tParams, translate]
  );

  /* prettier-ignore */
  const cnFlags = useValueMemo(() => ({
    '--has-size': !!fontSize,
    '--has-color': !!color,
    active, bold, code, disabled, em, medium,
    h1, h2, h3, h4, h5, h6,
    strikethrough,underline,
  }), [
    active, bold, color, disabled, em, fontSize,
    h1, h2, h3, h4, h5, h6, medium, strikethrough, underline
  ]);

  return (
    <TextDiv
      {...{ color, fontSize, ref, ...rest }}
      className={cn('core-text', cnFlags, className)}
      data-testid={dataTestId || tValue}
    >
      {tValue || text || children}
    </TextDiv>
  );
});
