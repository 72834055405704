import { JsonMap } from './Json.types';
import { ProductKey, ProductMap } from './Product';
import { IOxResource } from './Resource';

export interface UserActivity {
  id: number;
  userEmail: string;
  product: ProductKey;
  lastAccessedAt: string;
}

export type UserActivityMap = ProductMap<string | null>;

interface IUserRoleResource {
  id: number;
  enabled: boolean;
  resource: {
    id: number;
    enabled: boolean;
    name: string;
    type: string;
    product: {
      id: number;
      enabled: boolean;
      key: string;
      name: string;
    };
  };
}

interface IUserRole {
  id: number;
  global: boolean;
  name: string;
  value: string;
  roleResources: IUserRoleResource[];
}

interface IOxUser {
  auth0Id?: string;
  image: File | string;
  imageName?: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  role?: IUserRole;
  legalEntities: string[];
  expirationDate: string;
  activityMap?: UserActivityMap;
}

/**
 * Shape of the IOxUser when mutated for transmission to the backend; contains the following
 * differences:
 * - `role` is a string instead of {@link IUserRole}
 */
type IOxUserOutbound = Omit<IOxUser, 'role'> & {
  role: string;
};

interface IOxUserData extends IOxUser {
  activity: UserActivity[];
  auth0Id: string;
  status: UserStatus;
  isDeleted: boolean;
  acceptedInvite: boolean;
  resourcesPermissions?: IOxResource[];
  userConfig?: Record<string, JsonMap>;
  lastActivity?: Date;
  createdAt?: Date;
  updatedAt?: Date;
  adminUpdatedAt: string;
}

interface Auth0UserDetails {
  user_id: string;
  email: string;
  connection: string;
  email_verified: boolean;
  app_metadata: {
    company: string;
  };
}

enum UserStatus {
  Pending = 'Pending',
  Active = 'Active',
  Suspended = 'Suspended',
  Deleted = 'Deleted'
}

/*
 * We're using userConfig in @core/user-configs now, so turning this off, because it isn't being
 * used anywhere.
 *
 * For now, do NOT delete these types; they'll become the value type for a future config.
 */
// type UserConfig = JsonMap & {
//   pair?: PairConfig;
// };

// type PairConfig = {
//   performanceMetrics?: PerformanceMetrics;
//   tradeIndicators?: TradeIndicators;
// };

// interface PerformanceMetrics {
//   inboundDeals?: boolean;
//   outboundDeals?: boolean;
//   unpairedDeals?: boolean;
//   pairedX?: boolean;
//   terminatedDeals?: boolean;
//   pairedDeals?: boolean;
// }

// interface TradeIndicators {
//   onTrack?: TradeIndicator;
//   atRisk?: TradeIndicator;
//   offTrack?: TradeIndicator;
// }

// interface TradeIndicator {
//   start: number;
//   end: number;
// }

export { UserStatus };
export type {
  Auth0UserDetails,
  IOxUser,
  IOxUserData,
  IOxUserOutbound,
  IUserRole,
  IUserRoleResource
};

export interface ChangeLogType {
  auth0Id: string;
  firstName: string;
  lastName: string;
  email: string;
  status: UserStatus;
  isDeleted: boolean;
  acceptedInvite: boolean;
  userConfig: object;
  expirationDate: Date;
  legalEntities: string[];
  role: string;
}

export interface UserChangeLog {
  id: string;
  oldUser?: ChangeLogType;
  newUser: ChangeLogType;
  requester: string;
  timestamp: string;
}
