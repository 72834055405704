import { useAuth0 } from '@auth0/auth0-react';
import { OxLoadingV2 } from '@core/components';
import { useData, useDataMutation } from '@core/data-manager';
import { useValueMemo } from '@core/hooks';
import { CompanyConfig, CompanyConfigUpdateItem, ComposedSfc } from '@core/typings';
import { useLogout } from '@core/user';
import { isCyAuthed } from '@core/util';
import { AxiosError, AxiosResponse } from 'axios';
import { useEffect, useMemo } from 'react';
import { CompanyConfigContext } from './CompanyConfig.context';
import { CompanyConfigEndpoints } from './CompanyConfig.endpoints';
import { CompanyConfigCV, CompanyConfigLib } from './CompanyConfig.types';

export function withCompanyConfig(Composed: ComposedSfc) {
  return function WithCompanyConfig(p: any) {
    const { isAuthenticated } = useAuth0();
    const logout = useLogout();
    const {
      data: companyConfig = {} as CompanyConfig,
      isPending,
      isSuccess,
      refetch,
      dataUpdatedAt,
      isError,
      error
    } = useData<CompanyConfig, CompanyConfig, AxiosError>({
      key: CompanyConfigEndpoints.fetch,
      enabled: isAuthenticated || isCyAuthed()
    });

    useEffect(() => {
      if (isError && error?.status === 401) logout();
    }, [isError, error]);

    const { mutate: update, mutateAsync: updateAsync } = useDataMutation<
      CompanyConfigUpdateItem[],
      AxiosResponse,
      CompanyConfigUpdateItem[]
    >({
      key: CompanyConfigEndpoints.update
    });

    const lib = useMemo<CompanyConfigLib>(() => ({ refetch, update, updateAsync }), []);

    const value = useValueMemo<CompanyConfigCV>(
      () => ({ companyConfig, dataUpdatedAt, ...lib }),
      [dataUpdatedAt, isSuccess]
    );

    if ((isAuthenticated && isPending) || error?.status === 401) {
      return <OxLoadingV2 title='Loading Company Settings...' />;
    }
    return (
      <CompanyConfigContext.Provider value={value}>
        <Composed {...p} />
      </CompanyConfigContext.Provider>
    );
  };
}
