import { themes } from '@core/theme';
import { createClassName } from '@core/util';
import { ThemeProvider } from 'styled-components';
import { StyledTypography } from './OxTypography.styles';

interface TextProp {
  children?: string;
  className?: string;
  size?: TextSize;
  weight?: TextWeight;
  color?: TextColor;
  dataTestId?: string;
}

export enum TextSize {
  XSmall = 'xs',
  Small = 'sm',
  Medium = 'md',
  Large = 'lg',
  XLarge = 'xl',
  XXLarge = '2xl'
}

export enum TextWeight {
  Light = 'light',
  Regular = 'regular',
  Medium = 'medium',
  Bold = 'bold'
}

export enum TextColor {
  VeryLight = 'very-light',
  Light = 'light',
  Medium = 'medium',
  Dark = 'dark'
}

export function OxTypography({
  children: text,
  className = '',
  size = TextSize.Medium,
  weight = TextWeight.Medium,
  dataTestId,
  color
}: TextProp) {
  const isTextXSmall = size === TextSize.XSmall;
  const classes = createClassName([
    className,
    isTextXSmall ? 'text-xs' : `text-${size}`,
    `weight-${weight}`,
    color && `color-${color}`
  ]);
  return (
    <ThemeProvider theme={themes.lightTheme}>
      <StyledTypography className={classes} data-testid={dataTestId || 'Typography'}>
        {text}
      </StyledTypography>
    </ThemeProvider>
  );
}
