import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import _each from 'lodash/each';
import { buildGenericSlice } from 'redux/helpers/slice.helper';
import { FeatureFlagsThunks } from 'redux/thunks/featureFlags.thunks';
import { recordLimit } from 'Sections/Pair/utils/deal-helpers.helper';
import { IFeatureFlagRepository } from 'shared/api/interfaces/IFeatureFlagRepository';
import { container } from 'shared/api/inversify.config';
import { SERVICE_KEYS } from 'shared/api/keys.const';
import { FeatureFlags } from 'shared/models/FeatureFlags';

export const initialState: FeatureFlags = {
  nom: false,
  nomPhaseOne: false,
  hideQaFeatures: false,
  tariffRates: false,
  adjustableDateRange: {
    enabled: false,
    value: 365
  },
  dealSearchLimit: {
    enabled: false,
    value: recordLimit
  },
  alertBar: false,
  recognize: false
};

const repository = container.get<IFeatureFlagRepository>(SERVICE_KEYS.FEATURE_FLAG_REPOSITORY);
const thunks = new FeatureFlagsThunks(repository);

export const fetchFeatureFlags = thunks.fetch();

export const featureFlagsSlice = createSlice<FeatureFlags, SliceCaseReducers<FeatureFlags>>({
  ...buildGenericSlice<FeatureFlags>('featureFlags', initialState),
  extraReducers: (builder: any) => {
    builder.addCase(
      fetchFeatureFlags.fulfilled,
      (state: FeatureFlags, action: PayloadAction<FeatureFlags>) => {
        _each(action.payload, (val: any, key: string) => {
          if (key in initialState) {
            state[key as keyof FeatureFlags] = val;
          }
        });
      }
    );
  }
});

export const featureFlagsReducer = featureFlagsSlice.reducer;
