import { useValueMemo } from '@core/hooks';
import { OxRoute } from '@core/typings';
import { usePermissions } from '@core/user';
import routeStrings from 'Containers/Routing/routeStrings';
import _ from 'lodash';
import { lazy } from 'react';
import { useAppSelector } from 'redux/hooks';
import { RootState } from 'redux/store';

export const authenticatedRoutes: OxRoute[] = [
  {
    permission: '/',
    path: '',
    component: lazy(() => import('Sections/ProductSelection/ProductSelection'))
  },
  {
    permission: '/products',
    path: routeStrings.productSelection,
    component: lazy(() => import('Sections/ProductSelection/ProductSelection'))
  },
  {
    permission: '/products/admin',
    path: routeStrings.oxAdmin.root,
    component: lazy(() => import('Sections/Admin/Admin'))
  },
  {
    permission: '/products/oracle',
    path: routeStrings.oxOracle.root,
    component: lazy(() => import('Sections/Oracle/Oracle'))
  },
  {
    permission: '/products/pair',
    path: routeStrings.oxPair.root,
    component: lazy(() => import('Sections/Pair/routes/Pair'))
  },
  {
    permission: '/products/nom',
    path: routeStrings.oxNom.root,
    component: lazy(() => import('Sections/Nom/routes/Nom')),
    isHidden: ({ featureFlags }) => !featureFlags.nom
  },
  {
    permission: '/products/settle',
    path: routeStrings.oxSettle.root,
    component: lazy(() => import('Sections/Settle/Settle'))
  },
  {
    permission: '/products/settings/company',
    path: routeStrings.settings.root,
    component: lazy(() => import('Sections/Settings/Settings'))
  },
  {
    permission: '/products/support',
    path: routeStrings.support.root,
    component: lazy(() => import('Sections/Support'))
  }
];

export function useProductRoutes() {
  const { currentUser } = useAppSelector((state: RootState) => state.access);
  const { userCanVisit } = usePermissions();

  return useValueMemo<OxRoute[]>(() => {
    if (!currentUser) return [];
    return _.filter(authenticatedRoutes, ({ permission }: OxRoute) => {
      if (!permission) return false;
      if (permission === '/products/support') return true;
      return userCanVisit(permission);
    });
  }, [currentUser]);
}
