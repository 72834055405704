const nom = {
  nom: {
    scheduling: {
      title: 'Scheduling',
      businessUnitFilter: 'VIEW AS',
      targetPipelineFilter: 'FROM',
      pushAllChanges: 'Push All Changes',
      positionSummaryTable: {
        title: 'Position Summary',
        scopeSelect: 'Scope',
        betaNomsToggle: 'Temp Noms',
        schedulingQtyToggle: 'Sch Qty',
        groupColumns: {
          zone: 'ZN',
          location: 'LOC',
          counterparty: 'CPTY',
          fuel: 'Fuel',
          meter: 'METER',
          meterLocation: 'METER-LOC',
          ta: 'TA',
          grandTotal: 'Grand Total',
          supply: 'Receipt',
          demand: 'Delivery',
          receipts: 'Receipts',
          scheduledSupply: 'Sched Receipt',
          unmatchedSupply: 'Unmatched Supply',
          imbalance: 'Imbalance',
          deliveries: 'Deliveries',
          scheduledDemand: 'Sched Delivery',
          unmatchedDemand: 'Unmatched Demand',
          totalTransportSupply: 'Transport Receipts',
          totalTransportDemand: 'Transport Deliveries',
          schedQuantityImbalance: 'Sched Imbalance'
        }
      },
      mdqSegmentView: {
        mdqNonSegment: 'MDQ Check'
      },
      plannedVolumePositionTable: {
        title: 'Planned Volume Positions',
        addButton: 'Add',
        copyButton: 'Copy',
        columns: {
          criteria: {
            title: 'Criteria',
            children: {
              id: 'Id',
              pipeline: 'Pipeline',
              businessUnit: 'Business Unit',
              flowDate: 'Flow Date',
              counterparty: 'Counterparty',
              meter: 'Meter',
              location: 'Location',
              upstreamContract: 'U/S K',
              downstreamContract: 'D/S K'
            }
          },
          supply: {
            title: 'Supply',
            children: {
              planned: 'Planned',
              scheduled: 'Scheduled',
              imbalance: 'Imbalance'
            }
          },
          demand: {
            title: 'Demand',
            children: {
              planned: 'Planned',
              scheduled: 'Scheduled',
              imbalance: 'Imbalance'
            }
          }
        },
        actions: { delete: 'Delete', copy: 'Copy' }
      },
      nomTable: {
        nominations: 'Nominations',
        supply: 'Supply',
        demand: 'Demand',
        fuel: 'Fuel',
        id: 'ID',
        hold: 'Hold',
        seq: 'Seq',
        status: 'Status',
        greenGas: 'GGas',
        transportationAgreement: 'TA',
        transactionType: 'TT',
        packageId: 'Pkg ID',
        acctCode: 'Acct Code',
        rank: 'Rank',
        supplyRank: 'Rank',
        supplyPackageId: 'Pkg ID',
        supplyCounterparty: 'CPTY',
        supplyDuns: 'DUNS',
        supplyUsk: 'US/K',
        supplyTransactionType: 'TT',
        supplyMeter: 'MTR',
        supplyZone: 'ZN',
        supplyLocation: 'LOC',
        supplyNommedVolume: 'Nom Vol',
        supplyRemainingVolume: 'Rem Vol',
        supplyScheduledVolume: 'Sch Vol',
        loss: 'Loss',
        demandScheduledVolume: 'Sch Vol',
        demandRemainingVolume: 'Rem Vol',
        demandPackageId: 'Pkg ID',
        demandCounterparty: 'CPTY',
        demandDuns: 'DUNS',
        demandDsk: 'DS/K',
        demandTradeType: 'TT',
        demandMeter: 'MTR',
        demandZone: 'ZN',
        demandLocation: 'LOC'
      },
      editTable: {
        shortName: '',
        name: 'Name',
        duns: 'DUNS',
        isAffiliate: 'Affiliate'
      },
      tooltipErrors: {
        nonexistantCounterparty: 'Value does not match any known Counterparty',
        dunsCpMismatch: 'DUNs does not match Counterparty field',
        noMatchingCp: 'DUNs does not match any known Counterparty',
        nonexistantLocation: 'Value does not match any known Location'
      }
    },
    ediDashboard: {
      filters: {
        requiredFilterLabel: 'Required filters:',
        shipperDuns: 'Shipper DUNS',
        pipelineDuns: 'Pipeline DUNS',
        flowDate: 'Flow Date',
        cycleIndicator: 'Cycle Indicator'
      },
      views: {
        cutView: 'Cut View',
        detailView: 'Detail View'
      },
      statement: {
        statementId: 'Statement ID',
        statementTime: 'Statement Time',
        shipperDuns: 'Shipper DUNS',
        pipelineDuns: 'Pipeline DUNS',
        pipelineName: 'Pipeline Name',
        dateFormat: 'Date Format',
        startDate: 'Start Date',
        endDate: 'End Date',
        location: 'Location',
        nominatorsTrackingId: 'Nominators Tracking ID',
        transactionType: 'TT',
        transactionTypeDescription: 'TT Desc',
        transportationAgreement: 'Transportation Agreement',
        reductionReason: 'RR',
        reductionReasonDescription: 'RR Desc',
        subsequentCycleIndicator: 'Subsequent Cycle Indicator',
        subsequentCycleIndicatorDescription: 'Subsequent Cycle Indicator Desc',
        packageId: 'Package ID',
        upstreamCounterpartyDuns: 'Counterparty DUNS',
        upstreamCounterpartyName: 'Counterparty Name',
        receiptLocationPublicMeterId: 'Receipt Meter ID',
        meter: 'Meter',
        upstream: 'Upstream',
        upstreamContract: 'Contract',
        upstreamPackageId: 'Package ID',
        receiptRank: 'Receipt Rank',
        scheduledQuantityUOM: 'Sched Qty UOM',
        scheduledQuantity: 'Sched Qty',
        deliveryLocationPublicMeterId: 'Delivery Meter ID',
        downstreamCounterpartyDuns: 'Counterparty DUNS',
        downstreamCounterpartyName: 'Counterparty Name',
        downstreamContract: 'Contract',
        downstreamPackageId: 'Package ID',
        deliveryRank: 'Delivery Rank',
        nominatedQuantity: 'Nom Qty',
        nominatedQuantityUOM: 'Nominated Qty UOM',
        cutQuantity: 'Cut Qty',
        changeQuantity: 'Chg Qty',
        cycles: {
          TIM: 'TIM',
          EVE: 'EVE',
          ID1: 'ID1',
          ID2: 'ID2',
          ID3: 'ID3',
          TD1: 'TD1',
          TD8: 'TD8'
        }
      }
    },
    gasDaySummary: {
      views: {
        pntReceipt: 'Pathed Non-Threaded — Receipt',
        pntDelivery: 'Pathed Non-Threaded — Delivery'
      }
    },
    rates: {
      rateSchedules: {
        title: 'Rate Schedules',
        pipelineSelectPlaceHolder: 'Select pipeline...',
        columns: {
          id: 'Id',
          name: 'Name',
          description: 'Description',
          serviceType: 'Service Type',
          effectiveStart: 'Effective Start',
          effectiveEnd: 'Effective End',
          rowVersion: 'Row Version'
        }
      },
      providerChargeTypes: {
        title: 'Provider Charge Types',
        columns: {
          id: 'Id',
          pipelineId: 'Pipeline Id',
          name: 'Name',
          description: 'Description',
          amountPercent: 'Amount Percent'
        }
      },
      rateDefinitions: {
        title: 'Rate Definitions',
        columns: {
          id: 'Id',
          rateCategory: 'Rate Category',
          providerChargeType: 'Provider Charge Type',
          pipelineSeason: 'Pipeline Season',
          receiptRateLocationType: 'Receipt Rate Location Type',
          receiptLocation: 'Receipt Location',
          deliveryRateLocationType: 'Delivery Rate Location Type',
          deliveryLocation: 'Delivery Location',
          chargeRate: 'Charge Rate',
          effectiveStart: 'Effective Start',
          effectiveEnd: 'Effective End',
          chargeIndicator: 'Charge Indicator',
          custodyContract: 'Custody Contract',
          rowVersion: 'Row Version'
        }
      }
    },
    crossReference: {
      mappingDefintions: {
        title: 'Mapping Definitions',
        columns: {
          id: 'Id',
          externalSystem: 'External System',
          externalXrefAttribute: 'External Xref Attribute',
          internalXrefAttribute: 'Internal Xref Attribute',
          direction: 'Direction',
          editor: 'Editor',
          description: 'Description',
          comments: 'Comments'
        }
      },
      mappingValues: {
        title: 'Mapping Values',
        columns: {
          id: 'Id',
          mappingDefinitionId: 'Mapping Definition Id',
          internalValue: 'Internal Value',
          internalValueId: 'Internal Value Id',
          externalValue: 'External Value',
          effectiveStart: 'Effective Start',
          effectiveEnd: 'Effective End',
          comments: 'Comments',
          createdDate: 'Created Date',
          createdBy: 'Created By',
          modifiedDate: 'Modified Date',
          modifiedBy: 'Modified By'
        }
      }
    },
    fuelRateScenarios: {
      pipeline: 'Pipeline',
      pipelineSelectPlaceHolder: 'Select pipeline...',
      flowDate: 'Flow Date',
      title: 'Fuel Rate Scenarios',
      add: 'Add',
      table: {
        rateDefinitionId: 'Rate Definition Id',
        rateSchedule: 'Rate Schedule',
        totalCost: 'Total Cost',
        cof: 'Cost of Fuel %',
        cot: 'COT',
        zonePath: 'Zone Path',
        fuelCost: 'Fuel Cost',
        delVolume: 'Delivery Volume',
        recVolume: 'Receipt Volume',
        ta: 'TA',
        delLocation: 'Delivery Location',
        recLocation: 'Receipt Location'
      }
    },
    shared: {
      dataPlaceholder: {
        title: 'Get Your Data',
        description: 'Use the "Required Filters" above to display your inital desired data.'
      },
      locationName: 'Location Name'
    }
  }
} as const;

export default nom;
