import { DataMngrEndpointOptions } from '@core/data-manager';

export const ediSqtsEndpointMap: Record<string, DataMngrEndpointOptions> = {
  filter: {
    path: '/filter',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: 0,
    queryError: 'Error getting EDI SQTS data.'
  }
};
