export function safeJsonParse(jsonString: any): any | undefined {
  if (typeof jsonString !== 'string') {
    return;
  }

  try {
    return JSON.parse(jsonString);
  } catch (err) {
    console.error(`Failed to parse JSON: ${jsonString}`, err);
  }
}

export function safeJsonStringify(json: any): string | undefined {
  try {
    return JSON.stringify(json);
  } catch (err) {
    console.error(`Failed to stringify JSON: ${json}`, err);
  }
}
