import { useValueMemo } from '@core/hooks';
import { QueryKey, useMutation, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { useCallback } from 'react';
import { handleRequest } from '../lib';
import { DataMngrQueryMeta, UseDataMutationOptions } from '../typings';
import { getValidatedEndpoint } from './util';

export function useDataMutation<Data = any, Err = Error, Payload = any>(
  conf: UseDataMutationOptions<Data, Err>
) {
  const qClient = useQueryClient();
  const { endpoint, isValid, routeParams } = useValueMemo(
    () =>
      getValidatedEndpoint({
        key: conf.key,
        routeParams: conf?.routeParams,
        shouldBeMutation: true
      }),
    [conf.routeParams, conf.queryKey]
  );

  const mutationFn = useValueMemo(() => {
    if (isValid) {
      return async (data: Payload) =>
        handleRequest({
          ...endpoint?.query.meta,
          ...conf?.meta,
          requestOptions: {
            failOnBadRequest: true,
            ...endpoint?.query.meta.requestOptions,
            ...conf?.meta?.requestOptions,
            data
          },
          routeParams
        } as DataMngrQueryMeta);
    }
    /* this shouldn't be called – prior validation will catch; it's just a safety redundancy */
    return async () => console.warn('cannot mutate an invalid endpoint');
  }, [routeParams, isValid, endpoint?.indexKey, conf?.meta?.requestOptions]);

  const onSuccess = useCallback(
    (data: Data, variables: void, context: unknown) => {
      /* get a list of queries this mutation invalidates */
      const invalidates: QueryKey[] = _.map(
        _.uniq(_.compact(_.concat([], endpoint?.invalidates, conf?.invalidates))),
        (key: unknown): QueryKey => [key]
      );
      /* invalidate them */
      _.each(invalidates, (queryKey) => {
        qClient.invalidateQueries({ queryKey });
      });
      conf?.onSuccess?.(data, variables, context);
    },
    [endpoint?.invalidates, conf?.invalidates, conf?.onSuccess]
  );

  const onError = useCallback(
    (error: Err, variables: void, context: unknown) => {
      conf?.onError?.(error, variables, context);
    },
    [conf?.onError]
  );

  return useMutation<Data, Err, any, any>({
    mutationFn,
    meta: endpoint?.query.meta,
    ...conf,
    onSuccess,
    onError
  });
}
