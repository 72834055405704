import { IOxUserData, UserActivityMap } from '@core/typings';

const productAccess = ['oxAdmin', 'oxNom', 'oxOracle', 'oxPair', 'oxSettle'];

export const createUserActivityMap = (user: IOxUserData) => {
  const activityMap: UserActivityMap =
    user.activity?.reduce((memo, { product, lastAccessedAt }) => {
      memo[product] = lastAccessedAt;
      return memo;
    }, {} as UserActivityMap) || {};

  /* fill in missing gaps */
  productAccess.forEach((key) => {
    activityMap[key] = activityMap[key] || null;
    return;
  });
  return { ...user, activityMap };
};

export const formatUserData = (user: IOxUserData): IOxUserData => {
  if (user.activity) {
    return createUserActivityMap(user);
  } else {
    return user;
  }
};
