import { useMemoizeValue } from '@core/hooks';
import { useCss } from '@core/theme';
import { cn } from '@core/util';
import _ from 'lodash';
import { ForwardedRef, forwardRef } from 'react';
import { Text } from '../Text';
import { css } from './Btn.style';
import { BtnProps } from './Btn.types';

export const Btn = forwardRef(function Btn(props: BtnProps, ref: ForwardedRef<any>): JSX.Element {
  const {
    className,
    icon: Icon,
    iconProps,
    label,
    labelProps,
    dataTestId,
    /* passed to useCSS */
    bordered,
    borderWidth,
    block,
    borderRadius = 4,
    color = 'primary',
    dense,
    disabled,
    height,
    iconRight,
    inputGroup,
    mini,
    size = 'normal',
    styles,
    transitionDuration = 200,
    transitionType = 'ease-in-out',
    /* passed to button */
    ...rest
  } = props;
  const hasIcon = useMemoizeValue(!!Icon);
  const hasLabel = useMemoizeValue(!!label);
  const cls = useCss<BtnProps & { hasIcon: boolean; hasLabel: boolean }>(css, {
    block,
    bordered,
    borderRadius,
    borderWidth,
    color,
    dense,
    disabled,
    hasIcon,
    hasLabel,
    height,
    iconRight,
    inputGroup,
    label /* TODO: remove */,
    mini,
    size,
    styles,
    transitionDuration,
    transitionType
  });

  return (
    <button
      {...rest}
      disabled={disabled}
      ref={ref}
      data-testid={dataTestId}
      className={cn('core-btn', cls.btn, className)}
    >
      {Icon && <Icon {...iconProps} className={cn('btn-icon', iconProps?.className)} />}
      {label && _.isString(label) ? (
        <Text {...labelProps} className={cn('btn-label', labelProps?.className)} t={label} />
      ) : (
        label
      )}
    </button>
  );
});
