import { AxiosRequestConfig } from 'axios';
import _ from 'lodash';
import ms from 'ms';
import {
  DataMngrEndpoint,
  DataMngrEndpointOptions,
  DataMngrQuery,
  DataMngrSectionOptions,
  RequestMethod
} from '../typings';

import { EndpointMap } from './EndpointMap';
import { getDynamicRouteParams } from './getDynamicRouteParams';
import { indexRoute } from './indexRoute';

const dftOpts: DataMngrEndpointOptions = {
  isAuthedRequest: true,
  gcTime: '1 minute',
  extractData: true,
  meta: {},
  method: 'get',
  queryKey: [],
  requestOptions: {},
  staleTime: '5 minutes'
};

type CalcSecOptions = {
  sectionKeyPfx: string;
  uriRoot: string;
};

export const createRegisterEndpoint = (section: DataMngrSectionOptions & CalcSecOptions) => {
  const { getRequestOptions, defaultOptions: secDefaults = {}, sectionKeyPfx, uriRoot } = section;

  const { queryKey: secKeyParams } = secDefaults;
  return (route: DataMngrEndpointOptions, _key: string): DataMngrEndpoint => {
    const { key: routeKey = _key, path = _.kebabCase(_key), ...conf } = route;
    const {
      isAuthedRequest = true,
      queryFn,
      method: _method = 'get',
      gcTime,
      staleTime,
      requestOptions: axConf,
      queryKey: _qKey,
      extractData = true,
      meta: _meta,
      invalidates = [],
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      queryError, // queryError is not being used by data manager, ignore it
      ...passed
    }: DataMngrEndpointOptions = _.defaultsDeep({}, conf, secDefaults, dftOpts);

    const name = `${sectionKeyPfx}${routeKey}`;
    const trimmedPath = _.trim(path, '/');
    const url = `${_.startsWith(uriRoot, 'http') ? '' : '/'}${uriRoot}/${trimmedPath}`;

    /* generate axios request options */
    const requestOptions = {
      method: _.toLower(_method) as RequestMethod,
      url,
      ...axConf
    } as AxiosRequestConfig & { method: RequestMethod };

    const { method } = requestOptions;

    const indexKey = indexRoute(method, url, name);

    const isMutation = _.includes(['put', 'post', 'patch'], method);

    const routeKeyParams = [_.trim(sectionKeyPfx, '.'), name];
    const queryKey = _.compact(_.concat([], routeKeyParams, secKeyParams, _qKey));
    // console.log(_key, routeKeyParams, secKeyParams, _qKey, queryKey);

    const dynamicRouteKeys = getDynamicRouteParams(path);

    const query: DataMngrQuery = {
      ...passed,
      queryKey,
      gcTime: (_.isString(gcTime) ? ms(gcTime) : gcTime) as number,
      meta: {
        ..._meta,
        dynamicRouteKeys,
        extractData,
        key: routeKey,
        name,
        requestOptions,
        routeParams: {}
      }
    };

    if (!isMutation) {
      _.assign(query, {
        staleTime: _.isString(staleTime) ? ms(staleTime) : staleTime,
        /* only attach queryFn if it exists */
        ...(queryFn ? { queryFn } : {})
      });
    }
    const ret: DataMngrEndpoint = {
      indexKey,
      method,
      name,
      dynamicRouteKeys,
      getRequestOptions,
      hasDynamicUrl: dynamicRouteKeys.length > 0,
      invalidates,
      isAuthedRequest,
      isMutation,
      query
    };

    if (EndpointMap.has(name)) {
      console.warn(`[Query Registry]: overwriting conflicting query name ${name}`);
    }
    EndpointMap.set(name, ret);
    return ret;
  };
};
