/* NOTE: this file can't import anything from @core/redux until antipattern is resolved */
import { offline } from '@redux-offline/redux-offline';
import offlineConfig from '@redux-offline/redux-offline/lib/defaults';
import {
  Action,
  applyMiddleware,
  configureStore,
  StoreEnhancer,
  ThunkAction
} from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';

import { accessReducer } from 'redux/slices/access/access.slice';
import { authenticationReducer } from 'redux/slices/authentication/authentication.slice';
import { brokerReducer } from 'redux/slices/broker/broker.slice';
import { chargeTypeReducer } from 'redux/slices/chargeType/chargeType.slice';
import { configReducer } from 'redux/slices/config/config.slice';
import { counterpartyReducer } from 'redux/slices/counterparty/counterparty.slice';
import { dataTemplateReducer } from 'redux/slices/dataTemplates/dataTemplates.slice';
import { dealReducer } from 'redux/slices/deal/deal.slice';
import { dealActivityReducer } from 'redux/slices/dealActivity/dealActivity.slice';
import { featureFlagsReducer } from 'redux/slices/featureFlags/featureFlags.slice';
import { interconnectReducer } from 'redux/slices/interconnect/interconnect.slice';
import { legalEntityReducer } from 'redux/slices/legalEntity/legalEntity.slice';
import { locationReducer } from 'redux/slices/location/location.slice';
import { locationGroupReducer } from 'redux/slices/locationGroup/locationGroup.slice';
import { payIndexReducer } from 'redux/slices/payIndex/payIndex.slice';
import { pipelineReducer } from 'redux/slices/pipeline/pipeline.slice';
import { productReducer } from 'redux/slices/products/products.slice';
import { rateDefinitionReducer } from 'redux/slices/rateDefinition/rateDefinition.slice';
import { rateScheduleReducer } from 'redux/slices/rateSchedule/rateSchedule.slice';
import { RejectedEtrmInvoiceReducer } from 'redux/slices/settle/rejectedEtrmInvoices/rejectedEtrmInvoices.slice';
import { staticDataReducer } from 'redux/slices/staticData/staticData.slice';
import { tableFiltersReducer } from 'redux/slices/tableFilter/tableFilters.slice';
import { transportationAgreementReducer } from 'redux/slices/transportationAgreement/transportationAgreement.slice';
import { uploadReducer } from 'redux/slices/upload/upload.slice';
import { validationRowsReducer } from 'redux/slices/uploadValidationRows/uploadValidationRows.slice';
import { userPreferencesReducer } from 'redux/slices/userPreferences/userPreferences.slice';
import { zoneReducer } from 'redux/slices/zone/zone.slice';
import { resourceReducer } from './slices/resource/resources.slice';
import { roleReducer } from './slices/role/roles.slice';

const composedEnhancer = applyMiddleware(thunkMiddleware);

export const store = configureStore({
  reducer: {
    access: accessReducer,
    auth: authenticationReducer,
    broker: brokerReducer,
    chargeType: chargeTypeReducer,
    config: configReducer,
    counterparty: counterpartyReducer,
    dataTemplates: dataTemplateReducer,
    deal: dealReducer,
    dealActivity: dealActivityReducer,
    featureFlags: featureFlagsReducer,
    interconnect: interconnectReducer,
    legalEntity: legalEntityReducer,
    location: locationReducer,
    locationGroup: locationGroupReducer,
    payIndex: payIndexReducer,
    pipeline: pipelineReducer,
    rejectedEtrmInvoices: RejectedEtrmInvoiceReducer,
    product: productReducer,
    rateDefinition: rateDefinitionReducer,
    rateSchedule: rateScheduleReducer,
    staticData: staticDataReducer,
    tableFilters: tableFiltersReducer,
    transportationAgreement: transportationAgreementReducer,
    upload: uploadReducer,
    uploadValidationRows: validationRowsReducer,
    userPreferences: userPreferencesReducer,
    zone: zoneReducer,
    role: roleReducer,
    resource: resourceReducer
  },
  enhancers: [
    composedEnhancer,
    offline({
      ...offlineConfig,
      persistOptions: {
        blacklist: [
          'location',
          'zone',
          'pipeline',
          'interconnect',
          'counterparty',
          'legalEntity',
          'locationGroup',
          'payIndex',
          'broker',
          'config',
          'auth',
          'tableFilters',
          'deal',
          'chargeType',
          'transportationAgreement',
          'featureFlags',
          'access'
        ]
      }
    }) as StoreEnhancer
  ],
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
