import { Auth0ContextInterface } from '@auth0/auth0-react';
import { isCyAuthed } from '@core/util';

type GetIsEnabledConfig = {
  isValid?: boolean;
  isAuthedRequest?: boolean;
  enabled?: boolean;
  authState: Auth0ContextInterface;
};

/* determines whether request can be run */
export function getIsRequestEnabled(conf: GetIsEnabledConfig): boolean {
  const {
    authState: { isLoading, isAuthenticated }
  } = conf;

  return (
    conf?.isValid !== false &&
    (isCyAuthed() || conf?.isAuthedRequest === false || (!isLoading && isAuthenticated)) &&
    conf?.enabled !== false
  );
}
