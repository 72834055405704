import { OxLoadingV2 } from '@core/components';
import { useIsRouteHiddenParams } from '@core/routing';
import { OxRoute } from '@core/typings';
import routeStrings from 'Containers/Routing/routeStrings';
import _ from 'lodash';
import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useProductRoutes } from './useProductRoutes';

export const publicRoutes: OxRoute[] = [
  {
    path: routeStrings.login,
    component: lazy(() => import('Sections/Auth/Login/Login'))
  },
  {
    path: routeStrings.changePassword,
    component: lazy(() => import('Sections/Auth/ChangePassword/ChangePassword'))
  }
];
export function PageRoutes(): JSX.Element {
  const isRouteHiddenParams = useIsRouteHiddenParams();
  const productRoutes = useProductRoutes();

  return (
    <Suspense
      fallback={
        <div className='mt-4 text-center'>
          <OxLoadingV2 pageLevel />
        </div>
      }
    >
      <Routes>
        {_.map(publicRoutes, ({ path, component: Component, element, isHidden, ...rest }) =>
          isHidden?.(isRouteHiddenParams) ? null : (
            <Route
              key={`public-routes-${path}`}
              element={element || (Component ? <Component /> : undefined)}
              {...{ path, ...rest }}
            />
          )
        )}
        {_.map(productRoutes, ({ path, component: Component, element, ...rest }) => (
          <Route
            key={`private-routes-${path}`}
            element={element || (Component ? <Component /> : undefined)}
            {...{ Component, path, ...rest }}
          />
        ))}
      </Routes>
    </Suspense>
  );
}
