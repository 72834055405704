/* eslint-disable react/prop-types */

import {
  ButtonSize,
  ButtonType,
  OxButton,
  OxGridTooltip,
  OxSearchV2,
  SearchSize
} from '@core/components';
import { AddOutlineSvg, DeleteOutlineSvg, EditSvg } from '@core/images';
import { NomSource } from '@core/nom/typings';
import { formatterConfig, formatters } from '@core/table-util';
import {
  CellClickedEvent,
  ColDef,
  ICellRendererParams,
  ProcessCellForExportParams,
  ValueFormatterFunc
} from 'ag-grid-community';
import {
  CellClassParams,
  ColDefField,
  ColumnMenuTab,
  EditableCallbackParams
} from 'ag-grid-community/dist/lib/entities/colDef';
import { AgGridReact } from 'ag-grid-react';
import _ from 'lodash';
import numeral from 'numeral';
import { forwardRef, memo, useEffect, useImperativeHandle, useRef, useState } from 'react';
import ComplicatedNomSelector from '../../Components/ComplicatedNomSelector/ComplicatedNomSelector';
import { LogicalNomDay } from '../Classes/LogicalNomDay';
import { LogicalNomDayAllocation } from '../Classes/LogicalNomDayAllocation';
import { NompadColumnConfig } from '../Classes/NompadColumnConfig';
import { NomPadEntry } from '../Classes/NomPadEntry';
import { ExportState } from '../Enums/ExportState';
import { ModelState } from '../Enums/ModelState';
import { NomPadColumnCategory } from '../Enums/NomPadColumnCategory';
import { NompadColumnConfigUserEditabilityRule } from '../Enums/NompadColumnConfigUserEditabilityRule';
import { INompadColumnContentType } from '../Interfaces/INompadColumnContentType';

const clearValue = '-CLEAR-';

export const NomTableMultiSelector = memo(
  forwardRef((props: any, ref) => {
    const { context, fields, options, userOverride, fieldValue, onSelectAll, onClearAll } = props;
    const [value, setValue] = useState(props.value);
    const [done, setDone] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const clearAllFunc = () => {
      setValue(clearValue);
      onClearAll();
      setDone(true);
    };

    useEffect(() => {
      if (done) props.stopEditing(true);
    }, [done]);

    useEffect(() => {
      const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Escape') props.stopEditing(true);
      };
      window.addEventListener('keydown', handleKeyDown);
      return () => window.removeEventListener('keydown', handleKeyDown);
    }, []);

    useImperativeHandle(ref, () => ({
      getValue: () => {
        if (value === clearValue) {
          return '';
        }

        if (userOverride && searchValue.length && props.value === value) {
          return searchValue;
        }

        if (value) {
          return value;
        }

        return props.value;
      }
    }));

    const columns = fields.map((field: any) =>
      typeof field === 'string' ? { field, filter: 'agTextColumnFilter', sortable: true } : field
    );

    const grid = useRef<any>(null);

    const handleSearchInputKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowDown') {
        event.preventDefault();

        // Get the ag-Grid API
        if (grid.current) {
          const agGridApi = grid.current.api;
          const columnApi = grid.current.columnApi;

          // scrolls to the first column
          const firstCol = columnApi.getAllColumns()[0];
          agGridApi.ensureColumnVisible(firstCol);

          // sets focus into the first grid cell
          agGridApi.setFocusedCell(0, firstCol);
        }
      }
    };

    useEffect(() => {
      if (grid?.current) {
        // TODO: did all this so when you click out of the selector it closes.
        // This is all still pretty shaky
        const input: HTMLElement = document.querySelector(
          '[data-testid="OxDropdownSearchInput"]'
        ) as HTMLElement;
        if (input) {
          input.addEventListener('keydown', handleSearchInputKeyDown);
          input.focus();
        }
      }
      return () => {
        // Remove the event listener when the component unmounts
        const input: HTMLElement = document.querySelector(
          '[data-testid="OxDropdownSearchInput"]'
        ) as HTMLElement;

        if (input) {
          input.removeEventListener('keydown', handleSearchInputKeyDown);
        }
      };
    }, [grid]);

    useEffect(() => {
      if (grid.current?.api) {
        grid.current?.api.setQuickFilter(searchValue);
      }
    }, [searchValue, grid]);

    return (
      <div
        className='complicated-nom-selector '
        onBlur={(e) => {
          // Ignore blur when used outside of a grid
          if (onClearAll || onSelectAll) {
            return;
          }

          // Most of the time catches when a user has clicked outside the select. Messes up when changing column settings
          if (e.relatedTarget === null) props.stopEditing(true);
        }}
      >
        <div className='search-box'>
          <OxSearchV2
            searchText={searchValue}
            searchSize={SearchSize.compact}
            setSearchValue={setSearchValue}
            uniqueDataId='OxDropdownSearchInput'
          />
          {onClearAll && (
            <OxButton
              className='mt-2'
              buttonType={ButtonType.secondary}
              buttonSize={ButtonSize.compact}
              text='Clear All'
              onClick={clearAllFunc}
            />
          )}
          {onSelectAll && (
            <OxButton
              className='mt-2 ml-2'
              buttonType={ButtonType.secondary}
              buttonSize={ButtonSize.compact}
              text='Select All'
              onClick={onSelectAll}
            />
          )}
        </div>
        <div className='teh-grid'>
          <AgGridReact
            ref={grid}
            columnDefs={columns}
            rowHeight={25}
            headerHeight={30}
            rowData={
              _.isString(options) && !!context ? _.get(context, ['state', options]) : options
            }
            rowSelection='multiple'
            quickFilterText={searchValue}
            gridOptions={{
              rowMultiSelectWithClick: true
            }}
            onSelectionChanged={({ api }) => {
              const selectedRows = api.getSelectedRows();
              if (fieldValue) {
                setValue(selectedRows.map((row: any) => row[fieldValue]));
              } else {
                setValue(selectedRows.map((row: any) => row.id));
              }
            }}
            onFirstDataRendered={(event) => {
              if (columns.length < 4) {
                event.api.sizeColumnsToFit();
              }
            }}
            defaultColDef={{
              width: 150,
              resizable: true,
              flex: 1
            }}
          />
        </div>
      </div>
    );
  })
);

export const NomTableSelector = memo(
  forwardRef((props: any, ref) => {
    const {
      context,
      fields,
      options,
      onSelect,
      userOverride,
      fieldValue,
      onSelectAll,
      onClearAll,
      clearAllText,
      isWide
    } = props;

    const [value, setValue] = useState(props.value);
    const [done, setDone] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
      if (done) props.stopEditing(true);
    }, [done]);

    useEffect(() => {
      const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Escape') props.stopEditing(true);
      };
      window.addEventListener('keydown', handleKeyDown);
      return () => window.removeEventListener('keydown', handleKeyDown);
    }, []);

    useImperativeHandle(ref, () => ({
      getValue: () => {
        if (value === clearValue) {
          return '';
        }

        if (userOverride && searchValue.length && props.value === value) {
          return searchValue;
        }

        if (value) {
          return value;
        }

        return props.value;
      }
    }));

    return (
      <ComplicatedNomSelector
        key={Math.random()}
        onSelect={(v) => {
          setValue(v);
          onSelect && onSelect(v, props);

          setDone(true);
        }}
        onSelectAll={
          onSelectAll
            ? () => {
                onSelectAll();
                setDone(true);
              }
            : undefined
        }
        onClearAll={
          onClearAll
            ? () => {
                setValue(clearValue);
                onClearAll();
                setDone(true);
              }
            : undefined
        }
        clearAllText={clearAllText}
        fields={fields}
        options={_.isString(options) && !!context ? _.get(context, ['state', options]) : options}
        close={props.stopEditing}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        fieldValue={fieldValue}
        isWide={isWide}
      />
    );
  })
);

// bindingExpressionOnNomPad always has an uppercase letter in the beginning of the string,
export const lowercaseFirstLetter = (str: string) => {
  return str.charAt(0).toLowerCase() + str.slice(1);
};
// This is for nested data in the nom pad entries
export const reformatNestedString = (str: string, hasDynamicData: boolean) => {
  const splitString = str.split('.');

  const stringToRemove = 'Unbound';
  const index = splitString.indexOf(stringToRemove);

  // Covers Unbound Case but no columnSourcePropertyPath
  if (index !== -1) {
    splitString.splice(index, 1);
    splitString.unshift('dynamicData');
    return splitString.join('.');
  }

  //Covers the case where it has either Unbound or Item and also has columnSourcePropertyPath

  if (hasDynamicData) {
    splitString.unshift('dynamicData');
    return splitString.join('.');
  }

  // This covers only the bindingExpressionOnNomPad

  const reformattedString = splitString.map((string) => {
    return lowercaseFirstLetter(string);
  });

  return reformattedString.join('.');
};

export interface TranslatedColumnType extends ColDef {
  columnContentType: string;
  categoryId: NomPadColumnCategory;
  userEditabilityRule: NompadColumnConfigUserEditabilityRule;
}

export const schedulingPageZoom = 0.85;

export const translateColumnDataToAgGridColum = (
  column: NompadColumnConfig,
  bindingExpression: string,
  columnSourcePropertyPath: string | null,
  columnContentTypes: INompadColumnContentType[],
  isNomPad = false,
  isEbbExport = false,
  logicalNomDays?: LogicalNomDay[],
  setSelectedRowData?: any
): TranslatedColumnType => {
  const columnContentType =
    columnContentTypes.find((obj) => obj.id === column.contentTypeId)?.name || '';

  const isBindingExpressionNested = bindingExpression.includes('.');
  const pointToColumnSource =
    bindingExpression.includes('Item') || bindingExpression.includes('Unbound');
  const reformattedBindingExpression = isBindingExpressionNested
    ? reformatNestedString(bindingExpression, false)
    : lowercaseFirstLetter(bindingExpression);

  const reformattedColumnSource = columnSourcePropertyPath
    ? reformatNestedString(columnSourcePropertyPath, true)
    : reformattedBindingExpression;
  const isVolumeColumn =
    columnSourcePropertyPath === 'ReceiptVolume' || columnSourcePropertyPath === 'DeliveryVolume';

  return {
    field: pointToColumnSource ? reformattedColumnSource : reformattedBindingExpression,
    headerName: column.displayName,
    headerTooltip: column.displayName,
    initialHide: isNomPad ? !column.isVisibleOnNomPad : !column.isVisibleOnNomDetails,
    editable: isEbbExport ? false : defaultCellEditable,
    cellClass: isEbbExport ? 'ag-cell-not-editable' : defaultCellClass,
    userEditabilityRule: column.userEditabilityRule,
    categoryId: column.categoryId,
    columnContentType,
    ...(isVolumeColumn && {
      tooltipValueGetter: () => 'Press F3 to copy down',
      onCellClicked: (params: CellClickedEvent) => {
        if (params.column && params.rowIndex !== undefined && !isNomPad && !isEbbExport) {
          setSelectedRowData && setSelectedRowData(params);
        }
      }
    })
  };
};

export enum PositionSummaryScopeEnum {
  all = 'All',
  selectedNoms = 'Selected Noms',
  visibleNoms = 'Visible Noms'
}

type ModelStateCellRendererParams = { height?: number };

function ModelStateIcon(params: ICellRendererParams & ModelStateCellRendererParams) {
  const { height, value } = params;
  if (value === ModelState.Modified) {
    return <EditSvg height={height} />;
  } else if (value === ModelState.Added) {
    return <AddOutlineSvg height={height} />;
  } else if (value === ModelState.PendingDelete) {
    return <DeleteOutlineSvg height={height} />;
  }
  return null;
}

export function defaultModelStateColumn<TData = any>(
  cellRendererParams: ModelStateCellRendererParams = { height: 16 }
): ColDef<TData> {
  return {
    field: 'modelState' as ColDefField<TData>,
    headerName: '',
    headerTooltip: 'Model State',
    suppressColumnsToolPanel: true,
    suppressFiltersToolPanel: true,
    suppressMenu: true,
    resizable: false,
    maxWidth: 30,
    cellRenderer: ModelStateIcon,
    cellRendererParams,
    editable: false
  };
}

export const defaultNomSidebar = {
  toolPanels: [
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',
      toolPanelParams: {
        suppressPivots: true,
        suppressPivotMode: true,
        suppressRowGroups: true,
        suppressValues: true,
        contractColumnSelection: true
      }
    }
  ]
};

export const imbalanceCellRenderer = (params: any) => {
  const numberValue = formatters.number(params);
  if (params.value >= 0) {
    return numberValue;
  } else {
    return <div className='text-red-500'>{numberValue}</div>;
  }
};

const defaultMenuTabs: ColumnMenuTab[] = ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'];

export const defaultNomColDef = {
  resizable: true,
  filter: true,
  sortable: true,
  tooltipComponent: OxGridTooltip,
  menuTabs: defaultMenuTabs
};

export const defaultNomHistoryColDefs = (
  datetimeFormatter: ValueFormatterFunc<any, any>
): ColDef[] => [
  {
    field: 'createdBy',
    editable: false,
    cellClass: 'ag-cell-not-editable',
    initialHide: true
  },
  {
    field: 'createdDate',
    editable: false,
    cellClass: 'ag-cell-not-editable',
    initialWidth: 200,
    initialHide: true,
    valueFormatter: datetimeFormatter
  },
  {
    field: 'modifiedBy',
    editable: false,
    cellClass: 'ag-cell-not-editable',
    initialHide: true
  },
  {
    field: 'modifiedDate',
    editable: false,
    cellClass: 'ag-cell-not-editable',
    initialWidth: 200,
    initialHide: true,
    valueFormatter: datetimeFormatter
  }
];

export const defaultNomHistoryColDefsVisible = (
  datetimeFormatter: ValueFormatterFunc<any, any>
): ColDef[] =>
  defaultNomHistoryColDefs(datetimeFormatter).map((colDef) => ({
    ...colDef,
    initialHide: false
  }));

/* TODO: REMOVE HARD-CODED TW COLORS */
export const getExportStateColorClass = (value: number) => {
  const textColorClass = {
    [ExportState.Exported]: 'text-green-600',
    [ExportState.Pending]: 'text-yellow-600',
    [ExportState.Revised]: 'text-orange-600',
    [ExportState.Error]: 'text-red-600'
  }[value];

  return textColorClass;
};

export const defaultCellClass = (
  params: CellClassParams,
  omitNotEditableBackground: boolean = false
): string => {
  let isEditable = false;
  isEditable = defaultCellEditable(params);
  if (
    params.data.nomSource === NomSource.Pipeline &&
    !(
      params.column.getColId() === 'supply.receiptVolume' ||
      params.column.getColId() === 'demand.deliveryVolume'
    )
  ) {
    isEditable = false;
  }
  if (
    params.data.nomSource === NomSource.Pipeline &&
    (params.column.getColId() === 'supply.receiptVolume' ||
      params.column.getColId() === 'demand.deliveryVolume')
  ) {
    isEditable = true;
  }
  if (isEditable) {
    return '';
  } else {
    return omitNotEditableBackground
      ? 'ag-cell-not-editable-without-background'
      : 'ag-cell-not-editable';
  }
};

export const defaultCellEditable = (params: CellClassParams | EditableCallbackParams) => {
  const userEditabilityRule = (params.colDef as TranslatedColumnType).__metadata__
    ?.userEditabilityRule;
  const nom = params.data as NomPadEntry;

  if (nom.nomSource === NomSource.Pipeline) {
    return (
      params.column.getColId() === 'supply.receiptVolume' ||
      params.column.getColId() === 'demand.deliveryVolume'
    );
  }

  switch (userEditabilityRule) {
    case NompadColumnConfigUserEditabilityRule.Disallow:
      return false;
    case NompadColumnConfigUserEditabilityRule.AlwaysAllow:
      return true;
    case NompadColumnConfigUserEditabilityRule.DisallowAfterEbbExport:
      if (nom.hasEverBeenExportedToEbb || nom.hasEverBeenExportedToEdi) {
        return false;
      }
      break;
    case NompadColumnConfigUserEditabilityRule.DisallowAfterEndurExport:
      if (nom.hasEverBeenExportedToEndur) {
        return false;
      }
      break;
    case NompadColumnConfigUserEditabilityRule.DisallowAfterEbbOrEndur:
      if (
        nom.hasEverBeenExportedToEbb ||
        nom.hasEverBeenExportedToEndur ||
        nom.hasEverBeenExportedToEdi
      ) {
        return false;
      }
      break;
  }

  return true;
};

export const rowCountStatusBar = {
  statusPanels: [{ statusPanel: 'agTotalRowCountComponent', align: 'right' }]
};

export const rankColumnProps = {
  cellEditor: 'agNumberCellEditor',
  cellEditorParams: {
    min: 1,
    max: 999,
    precision: 0
  }
};

export const getNommedVolume = (allocations: LogicalNomDayAllocation[]) =>
  _.reduce(allocations, (sum, e) => sum + e.nominatedVolume, 0);
export const allocationFormatter = (v: number) =>
  numeral(v).format(formatterConfig.number.formatString);

export const defaultNoRowsOverlay = 'No Results';

// This is to ensure data exports correctly when valueFormatter is used and underlying value is a number
// Often needed when grid contains columns that use NomTableSelector
export const defaultExcelExportParams = {
  processCellCallback: (params: ProcessCellForExportParams) =>
    params.formatValue ? params.formatValue(params.value) : params.value
};
